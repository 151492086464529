import React, { useState, useEffect } from "react";
import MenuIconAdd from "../../../assets/images/icons-menu/MenuIconAdd";
import MenuIconAide from "../../../assets/images/icons-menu/MenuIconAide";
import { NavLink, useLocation } from 'react-router-dom';
import { PRIVATE_ROUTE } from '../../../config/routes';
import MenuIconDevis from '../../../assets/images/icons-menu/MenuIconDevis';
import MenuIconCommandes from '../../../assets/images/icons-menu/MenuIconCommandes';
import { useDispatch, useSelector } from "react-redux";
import * as actionTypes from "../../../store/functions/actionTypes";

export default function SidebarClient() {
    const location = useLocation();
    const [elementHeight, setElementHeight] = useState(0);
    const stateDevis = useSelector((state) => state.nosave);
    const dispatch = useDispatch();

  useEffect(() => {
    const updateElementHeight = () => {
      const element = document.querySelector('.menuSide');
      if (element) {
        const height = element.offsetHeight;
        setElementHeight(height);
      }
    };

    updateElementHeight(); // Get initial height on component mount

    window.addEventListener('resize', updateElementHeight); // Update height on window resize

    return () => {
      window.removeEventListener('resize', updateElementHeight); // Clean up event listener on component unmount
    };

    
  }, []);
    useEffect(() => {
        const handleClick = (event) => {
            const target = event.target;
            const previousElement = target.previousElementSibling;

            if (target.classList.contains('parent-active') && previousElement) {
                previousElement.classList.add('previous');
            } else {
                previousElement && previousElement.classList.remove('previous');
            }
            document.querySelector("body").classList.remove("toggled-sideNav");
        };

        const elements = document.querySelectorAll('.group-side-menu li');
        elements.forEach((element) => {
            const target = element;
            const previousElement = target.previousElementSibling;

            if (target.classList.contains('parent-active') && previousElement) {
                previousElement.classList.add('previous');
            } else {
                previousElement && previousElement.classList.remove('previous');
            }
            element.addEventListener('click', handleClick);
        });

        return () => {
            elements.forEach((element) => {
                element.removeEventListener('click', handleClick);
            });
        };
    }, []);

    const updateDevisDispatch = (a, b, c) => {
        dispatch({
            type: actionTypes.NOSAVE,
            nosave: a,
            lanch: b,
            link: c,
        });
    };
    return (
        <div className="menuSide" style={{ '--height-menuSide': `${elementHeight}px` }}>
            
            <ul className="group-side-menu">
                <li className={location.pathname === PRIVATE_ROUTE.DEVIS_ADD.url ? 'parent-active' : ''}>
                    <NavLink
                        to={`${PRIVATE_ROUTE.DEVIS_ADD.url}`}
                    >
                        <span className="icon-side">
                            <MenuIconAdd />
                        </span>
                        <span className="title-item-menu">Nouveau devis</span>
                    </NavLink>
                </li>
                {
                    stateDevis.nosave
                    ?
                    (
                        <>
                            <li className={``} onClick={(e) => {
                                updateDevisDispatch(true, true, PRIVATE_ROUTE.HOME.url)
                            }}>
                                <a>
                                    <span className="icon-side">
                                        <MenuIconDevis />
                                    </span>
                                    <span className="title-item-menu">Mes devis</span>
                                </a>
                            </li>
                            <li className={``} onClick={(e) => {
                                updateDevisDispatch(true, true, PRIVATE_ROUTE.COMMANDES.url)
                            }}>
                                <a>
                                    <span className="icon-side">
                                        <MenuIconCommandes />
                                    </span>
                                    <span className="title-item-menu">Mes commandes</span>
                                </a>
                            </li>
                        </>
                    )
                    :
                    (
                        <>
                            <li className={location.pathname === PRIVATE_ROUTE.HOME.url ? 'parent-active' : ''}>
                                <NavLink to={PRIVATE_ROUTE.HOME.url}>
                                    <span className="icon-side">
                                        <MenuIconDevis />
                                    </span>
                                    <span className="title-item-menu">Mes devis</span>
                                </NavLink>
                            </li>
                            <li className={location.pathname === PRIVATE_ROUTE.COMMANDES.url ? 'parent-active' : ''}>
                                <NavLink to={PRIVATE_ROUTE.COMMANDES.url}>
                                    <span className="icon-side">
                                        <MenuIconCommandes />
                                    </span>
                                    <span className="title-item-menu">Mes commandes</span>
                                </NavLink>
                            </li>
                        </>
                        
                    )
                }
            </ul>
            <ul className="group-side-menu">
                <li className="nav-link-aide">
                    <a href={`mailto:commandes@mesa.fr`} target="_blank">
                        <span className="icon-side">
                            <MenuIconAide />
                        </span>
                        <span className="title-item-menu">Besoin d'aide ?</span>
                    </a>
                </li>
            </ul>
        </div>
    );
}
