import React, { useState, useEffect} from "react";
import MenuIconAide from "../../../assets/images/icons-menu/MenuIconAide";
import { useLocation, NavLink } from 'react-router-dom';
import { PRIVATE_ROUTE } from '../../../config/routes';
import MenuIconCommandes from '../../../assets/images/icons-menu/MenuIconCommandes';

export default function SidebarAdmin() {
    const location = useLocation();
    const [elementHeight, setElementHeight] = useState(0);

    useEffect(() => {
        const updateElementHeight = () => {
            const element = document.querySelector('.menuSide');
            if (element) {
                const height = element.offsetHeight;
                setElementHeight(height);
            }
        };

        updateElementHeight(); // Get initial height on component mount

        window.addEventListener('resize', updateElementHeight); // Update height on window resize

        return () => {
            window.removeEventListener('resize', updateElementHeight); // Clean up event listener on component unmount
        };
    }, []);

    useEffect(() => {
        const handleClick = (event) => {
            const target = event.target;
            const previousElement = target.previousElementSibling;

            if (target.classList.contains('parent-active') && previousElement) {
                previousElement.classList.add('previous');
            } else {
                previousElement && previousElement.classList.remove('previous');
            }
            document.querySelector("body").classList.remove("toggled-sideNav");
        };

        const elements = document.querySelectorAll('.group-side-menu li');
        elements.forEach((element) => {
            const target = element;
            const previousElement = target.previousElementSibling;

            if (target.classList.contains('parent-active') && previousElement) {
                previousElement.classList.add('previous');
            } else {
                previousElement && previousElement.classList.remove('previous');
            }
            element.addEventListener('click', handleClick);
        });

        return () => {
            elements.forEach((element) => {
                element.removeEventListener('click', handleClick);
            });
        };
    }, []);
    return (
        <div className="menuSide" style={{ '--height-menuSide': `${elementHeight}px` }}>
            
            <ul className="group-side-menu">
                <li className={location.pathname === PRIVATE_ROUTE.HOME.url ? 'parent-active' : ''}>
                    <NavLink to={PRIVATE_ROUTE.HOME.url}>
                        <span className="icon-side">
                            <MenuIconCommandes />
                        </span>
                        <span className="title-item-menu">Commandes</span>
                    </NavLink>
                </li>
                <li className={location.pathname === PRIVATE_ROUTE.CLIENTS.url ? 'parent-active' : ''}>
                    <NavLink to={PRIVATE_ROUTE.CLIENTS.url}>
                        <span className="icon-side">
                            <MenuIconCommandes />
                        </span>
                        <span className="title-item-menu">Clients</span>
                    </NavLink>
                </li>
            </ul>
        </div>
    );
}
