import { rgba } from "polished";
import { rgbaToRgb } from "../../helper/functions";

export const DefaultPrimaryColor = "#A94B38";
export const DefaultPrimaryColorLight = rgbaToRgb(rgba(DefaultPrimaryColor, .5));
export const DefaultSecondaryColor = "#FFE248";
export const DefaultLabelColor = "#FFFFFF";


export const mixinIcon = ({ urlIcon, width, height, important = false }) => `
  width: ${width}px;
  min-width: ${width}px;
  height: ${height}px;
  background: url(${urlIcon}) ${important ? "!important" : ""};
  display: inline-block;
  vertical-align: middle;
  background-repeat: no-repeat !important;
  background-size: contain !important;
`;
