import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import jwtDecode from "jwt-decode";
import { NotificationContainer } from "react-notifications";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-notifications/lib/notifications.css";
import "./App.css";
import endPoints from "./config/endPoints";
import connector from "./connector";
import Router from "./router";
import Loader from "./components/loader";
import {
  REFRESH_TOKEN,
  REFRESH_TOKEN_FAIL,
} from "./store/functions/actionTypes";
function App() {
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const [isDone, setIsDone] = useState(false);
  
  useEffect(() => {
    const token = localStorage.getItem("token");
    const refresh_token = localStorage.getItem("refresh_token");
    if (token === undefined || refresh_token === undefined) {
      setIsDone(true);
      return;
    }
    let dateExp =
      token !== null ? new Date(jwtDecode(token).exp * 1000) : false;
    if (dateExp !== false && dateExp > Date.now()) {
      dispatch({
        type: REFRESH_TOKEN,
        token: token,
      });
      setIsDone(true);
    } else if (refresh_token !== null)
      connector({
        method: "post",
        url: endPoints.REFRESH_TOKEN,
        data: {
          refresh_token: refresh_token,
        },
        success: (response) => {
          dispatch({
            type: REFRESH_TOKEN,
            token: response.data.token,
          });
          setIsDone(true);
        },
        catch: (error) => {
          dispatch({
            type: REFRESH_TOKEN_FAIL,
          });
          setIsDone(true);
        },
      });
    else setIsDone(true);
  }, []);
  return (
    <div>
      {isDone ? <Router /> : <Loader />}
      <NotificationContainer />
    </div>
  );
}
export default App;