import React, { useEffect, useState } from 'react';
import { HeadOptions, HeaderPage } from '../../assets/styles/components/globalStyle';
import { useMediaQuery } from 'react-responsive';
import { IconMenuBurgger } from '../../assets/styles/components/iconsComponents';
import { Dropdown, DropdownButton } from 'react-bootstrap';
import MenuIconUser from '../../assets/images/icons-menu/MenuIconUser';
import IconLogout from '../../assets/images/icons/IconLogout';
import { PUBLIC_ROUTE, PRIVATE_ROUTE } from '../../config/routes';
import { Navigate } from "react-router";
import { Link, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from "react-redux";
import * as actionTypes from "../../store/functions/actionTypes";

export default function Header({ title, infoPage, urlVideo, ...props }) {
    const isMobile = useMediaQuery({
        query: "(max-width: 993px)",
    });
    const dispatch = useDispatch();
    const auth = useSelector((state) => state.auth);
    const [toggleMenuM, setToggleMenuM] = useState(false);

    const [needConnection] = useState(
        Object.values(PRIVATE_ROUTE).some(
            (route) => route.url === window.location.pathname
        )
    );
    const navigate = useNavigate();
    const logout = () => {
        dispatch({
            type: actionTypes.LOGOUT,
        });

        navigate('/connexion')
    };

    useEffect(() => {
        if (isMobile){
            if (toggleMenuM) {
                document.querySelector('body').classList.add("toggled-sideNav");
                setToggleMenuM(false)
            } 
        }
    }, [toggleMenuM]);

    if (auth.token === null && needConnection)
    return <Navigate to={PUBLIC_ROUTE.LOGIN.url} />;

    return (
        <HeaderPage>
            <HeadOptions>
                {isMobile && <div className='bloc-toggle-mobile'><span className="toggle-menu-mobile" onClick={() => { setToggleMenuM(true) }}><IconMenuBurgger /></span></div>}
                <div className="user-bloc-info">
                    <span className="icon-user-profil"><MenuIconUser /></span> 
                    {auth.user.firstName} {auth.user.lastName}
                </div>
                <div className='logout-menu' onClick={(e) => {
                            e.preventDefault();
                            logout();

                        }}>
                    <IconLogout/>
                    <span>Déconnexion</span>
                </div>
            </HeadOptions>
        </HeaderPage>
    )
}
