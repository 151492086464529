import * as actionTypes from "../functions/actionTypes";


const initState = {
	nosave: false,
};

const updateObject = (oldObject, newObject) => {
  return {
    ...oldObject,
    ...newObject,
  };
};

const DevisReducer = (state = initState, action) => {
  switch (action.type) {
    case actionTypes.NOSAVE:
      return updateObject(state, {
        nosave: action.nosave,
        lanch: action.lanch,
        link: action.link,
      });
    default:
      return state;
  }
};

export default DevisReducer;
