const endPoints = {
    LOGIN: "/api/login_check",
    REFRESH_TOKEN: "/api/token/refresh",
    REGISTRATION: "/api/user/register", 
    CHECK_TOKEN_VERIFY_EMAIL: "/api/user/check_token_verify_email",
    REST_PASSWORD: "/api/user/rest_password",
    CHECK_TOKEN_REST_PASSWORD: "/api/user/check_token_rest_password",
    FORGOT_PASSWORD: "/api/user/forgot_password",
    BANDS: "/api/band/all",
    BANDS_PRODUCT_BY_BAND: "/api/band/products",
    BANDS_PRODUCT_PRICE: "/api/band/product/price",
    BANDS_PRODUCT_DELIVERY: "/api/band/product/delivery",
    QUOTE: "/api/quote",
    QUOTE_LOAD: "/api/quote/load",
    QUOTE_ADD: "/api/quote/add",
    QUOTE_EDIT: "/api/quote/edit",
    QUOTE_ALL: "/api/quote/all",
    QUOTE_REMOVE: "/api/quote/delete",
    QUOTE_DUPLICATE: "/api/quote/duplicate",
    QUOTE_ORDER: "/api/quote/order",
    COMMANDE_ALL: "/api/commande/all",
    COMMANDE_REMOVE: "/api/commande/delete",
    COMMANDE_DUPLICATE: "/api/commande/duplicate",
    COMMANDE_UPDATE_DATE_EXPEDITION: "/api/commande/date_expedition",
    COMMANDE_REFUSE_VALIDATE: "/api/commande/refuse",
    CLIENT_ALL: "/api/client/all",
    CLIENT_ENABLE_DISABLE: "/api/client/enable-disable",
};
export default endPoints;
