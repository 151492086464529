import React, { Fragment, lazy, Suspense } from "react";
import { Route, Navigate, BrowserRouter, Routes } from "react-router-dom";
import { useSelector } from "react-redux";
import { PRIVATE_ROUTE, PUBLIC_ROUTE } from "./config/routes";
import Loader from "./components/loader";
import NotFound from "./views/layouts/notFound";

export default function Router() {
  const loadRoutes = (auth) => {
    return [
      {
        exact: true,
        path: PRIVATE_ROUTE.HOME.url,
        component: lazy(() => {
          if (auth?.roles.includes("ROLE_ADMIN")) {
            return import("./views/commande/list");
          } else {
            return import("./views/quote/list");
          }
        }),
        roles: PRIVATE_ROUTE.HOME.roles,
      },
      {
        exact: true,
        path: PUBLIC_ROUTE.LOGIN.url,
        component: lazy(() => import("./views/auth/login")),
        roles: PUBLIC_ROUTE.LOGIN.roles,
      },
      {
        exact: true,
        path: PUBLIC_ROUTE.REGISTER.url,
        component: lazy(() => import("./views/auth/register")),
        roles: PUBLIC_ROUTE.REGISTER.roles,
      }, 
      {
        exact: true,
        path: PUBLIC_ROUTE.FORGOTPASSWORD.url,
        component: lazy(() => import("./views/auth/forgotPassword")),
        roles: PUBLIC_ROUTE.FORGOTPASSWORD.roles,
      },
      {
        exact: true,
        path: PUBLIC_ROUTE.RESETPASSWORD.url,
        component: lazy(() => import("./views/auth/restPassword")),
        roles: PUBLIC_ROUTE.RESETPASSWORD.roles,
      },
      {
        exact: true,
        path: PUBLIC_ROUTE.VERIFYEMAIL.url,
        component: lazy(() => import("./views/auth/verifyEmail")),
        roles: PUBLIC_ROUTE.VERIFYEMAIL.roles,
      },
      {
        exact: true,
        path: `${PRIVATE_ROUTE.DEVIS.url}`,
        component: lazy(() => import("./views/quote/list")),
        roles: PRIVATE_ROUTE.DEVIS.roles,
      },
      {
        exact: true,
        path: `${PRIVATE_ROUTE.DEVIS_ADD.url}`,
        component: lazy(() => import("./views/quote/add")),
        roles: PRIVATE_ROUTE.DEVIS_ADD.roles,
      },
      {
        exact: true,
        path: `${PRIVATE_ROUTE.DEVIS.url}/:id`,
        component: lazy(() => import("./views/quote/add")),
        roles: PRIVATE_ROUTE.DEVIS.roles,
      },
      {
        exact: true,
        path: `${PRIVATE_ROUTE.COMMANDES.url}`,
        component: lazy(() => import("./views/commande/list")),
        roles: PRIVATE_ROUTE.COMMANDES.roles,
      },
      {
        exact: true,
        path: `${PRIVATE_ROUTE.CLIENTS.url}`,
        component: lazy(() => import("./views/client/list")),
        roles: PRIVATE_ROUTE.CLIENTS.roles,
      },
      {
        exact: true,
        path: `${PRIVATE_ROUTE.CGU.url}`,
        component: lazy(() => import("./views/default/termsOfService")),
        roles: PRIVATE_ROUTE.CGU.roles,
      }
    ]
  }
  
  const auth = useSelector((state) => state.auth);
  const myRoutes = loadRoutes(auth);
  console.log(auth)
  return (
    <Suspense fallback={<Loader />}>
      <BrowserRouter>
        <Routes>
          {myRoutes.map((route, index) =>
            route.roles.length === 0 ||
            route.roles.filter((value) => auth.roles.includes(value)).length ? (
              <Route key={index} path={route.path} exact={route.exact} element={<route.component />} />
            ) : 
              auth.token === null ? <Route key={index}
                path="*"
                element={
                  <Navigate
                    to={{
                      pathname: PUBLIC_ROUTE.LOGIN.url,
                      state: { from: "/" },
                    }}
                  />
                }
              /> :
             (
                <Route key={index} path="*" element={<NotFound />} />
            )
          )}
          
          
         
        </Routes>
      </BrowserRouter>
    </Suspense>
  );
}