import React from "react";
import { FooterBloc } from "../../assets/styles/components/globalStyle";
import { Link } from "react-router-dom";

export default function Footer() {
    return (
        <FooterBloc>
            <div className="copyright-footer">
                © Copyright 2023 - Mesa
            </div>
            <ul className="footer-navs">
                <li>
                    <Link to="/conditions-generales-d-utilisation"
                    >
                        Conditions générales d’utilisation
                    </Link>
                </li>
                <li>
                    Réalisé par {" "}
                    <Link to="https://www.feelandclic.com/" target={"_blank"}>
                         Feel & Clic
                    </Link>
                </li>
            </ul>
        </FooterBloc>
    );
}
