import React from "react";
import { createRoot } from "react-dom/client";
import { createStore, applyMiddleware, compose, combineReducers } from "redux";
import { Provider } from "react-redux";
import thunk from "redux-thunk";
import "react-notifications/lib/notifications.css";
import logger from "./store/middlewares/logger";
import App from "./App";
import AuthReducer from "./store/reducers/auth";
import DevisReducer from "./store/reducers/devis";

const REACT_VERSION = React.version;
console.log("[React Version] ", REACT_VERSION);

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const rootReducer = combineReducers({
  auth: AuthReducer,
  nosave: DevisReducer
});
const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(logger, thunk))
);

createRoot(document.getElementById('root')).render(
  <Provider store={store}>
    <App />
  </Provider>
);

