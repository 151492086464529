import React from "react";
import { ButtenDef } from "../../assets/styles/components/elementStyles";
import Loader from "../loader";

export default function ButtonDefault({
    href,
    variant,
    disabled = false,
    type = "",
    onClick,
    className = "",
    children,
    loading = false,
}) {
    return loading ? (
        <div className="row-bodyCalendar" style={{ position: "relative", height: "130px" }}>
            <Loader size="md" />
        </div>
    ) : (
        <ButtenDef
            href={href}
            variant={variant}
            disabled={disabled}
            onClick={onClick}
            type={type}
            className={`btn-default ${className}`}
        >
            {children}
        </ButtenDef>
    );
}
