import React from 'react'

export default function MenuIconCommandes() {
  return (
    <svg width="18px" height="19px" viewBox="0 0 18 19" version="1.1">
        <g id="Commandes-icon" stroke="none" strokeWidth={1} fill="none">
            <g id="Devis->-Liste" transform="translate(-34.000000, -266.000000)" fill="#E0BEB7">
                <g id="Menu-Nav" transform="translate(19.000000, 158.574818)">
                    <g id="Group-4" transform="translate(0.000000, 53.916064)">
                        <g id="Navigation-Web-/-Fullsize-Items-/-Resting" transform="translate(15.000000, 53.000000)">
                            <g id="dataset_FILL0_wght300_GRAD-25_opsz48" transform="translate(0.000000, 1.000000)">
                                <path d="M4.19287834,13.8071217 L7.50445104,13.8071217 L7.50445104,10.495549 L4.19287834,10.495549 L4.19287834,13.8071217 Z M10.5222552,13.8071217 L13.8338279,13.8071217 L13.8338279,10.495549 L10.5222552,10.495549 L10.5222552,13.8071217 Z M4.19287834,7.47774481 L7.50445104,7.47774481 L7.50445104,4.16617211 L4.19287834,4.16617211 L4.19287834,7.47774481 Z M10.5222552,7.47774481 L13.8338279,7.47774481 L13.8338279,4.16617211 L10.5222552,4.16617211 L10.5222552,7.47774481 Z M1.49554896,18 C1.08605341,18 0.734421365,17.8531157 0.440652819,17.5593472 C0.146884273,17.2655786 4.74397969e-16,16.9139466 4.74397969e-16,16.504451 L4.74397969e-16,1.49554896 C4.74397969e-16,1.08605341 0.146884273,0.734421365 0.440652819,0.440652819 C0.734421365,0.146884273 1.08605341,9.48795938e-16 1.49554896,9.48795938e-16 L16.504451,9.48795938e-16 C16.9139466,9.48795938e-16 17.2655786,0.146884273 17.5593472,0.440652819 C17.8531157,0.734421365 18,1.08605341 18,1.49554896 L18,16.504451 C18,16.9139466 17.8531157,17.2655786 17.5593472,17.5593472 C17.2655786,17.8531157 16.9139466,18 16.504451,18 L1.49554896,18 Z M1.49554896,16.8249258 L16.504451,16.8249258 C16.5934718,16.8249258 16.6691395,16.7937685 16.731454,16.731454 C16.7937685,16.6691395 16.8249258,16.5934718 16.8249258,16.504451 L16.8249258,1.49554896 C16.8249258,1.40652819 16.7937685,1.33086053 16.731454,1.26854599 C16.6691395,1.20623145 16.5934718,1.17507418 16.504451,1.17507418 L1.49554896,1.17507418 C1.40652819,1.17507418 1.33086053,1.20623145 1.26854599,1.26854599 C1.20623145,1.33086053 1.17507418,1.40652819 1.17507418,1.49554896 L1.17507418,16.504451 C1.17507418,16.5934718 1.20623145,16.6691395 1.26854599,16.731454 C1.33086053,16.7937685 1.40652819,16.8249258 1.49554896,16.8249258 Z M1.17507418,1.17507418 L1.17507418,16.8249258 L1.17507418,1.17507418 L1.17507418,1.17507418 Z" id="Shape" />
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </svg>
  )
}
