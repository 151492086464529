import React from "react";
import { MainContent, MainPage } from "../../assets/styles/components/globalStyle";
//import { useUserContext } from "../../context/UserContext";
import Footer from "./footer";
import Sidebar from "./sidebar";

export default function Base({ children, navActive, authUser = null, ...props }) {
    // const [show, setShow] = useState(false);

    // let { pathname } = window.location;
    // const { isAuthenticated, loginUrl } = useUserContext();

    // const [pageNotAuth] = useState([
    //     "/connexion",
    //     "/connexion-mairie",
    //     "/inscription",
    //     "/mot-de-passe-oublie",
    //     "/reinitialisation-du-mot-de-passe",
    // ]);

    // useEffect(() => {
    //     if (pageNotAuth.includes(pathname) && isAuthenticated()) {
    //         window.location.href = "/";
    //     } else if (!pageNotAuth.includes(pathname) && !isAuthenticated()) {
    //         window.location.href = loginUrl();
    //     } else {
    //         setShow(true);
    //     }
    // }, []);

    return (
            <MainPage>
                <Sidebar />
                <MainContent id="main-content" className={props.className}>
                    <div className="main-wrapper">{children}</div>
                    <Footer />
                </MainContent>
            </MainPage>
    );
}
