import styled from "styled-components";
import {
    DefaultPrimaryColor,
    DefaultSecondaryColor,
    DefaultLabelColor
    
} from "../variables";

export const SideNavMenu = styled.div`
    background-color: ${DefaultPrimaryColor};
    color: #fff;
    min-width: 244px;
    transition: 0.2s ease-in-out;
    position: relative;
    display: flex;
    flex-direction: column;
    .bloc-sideNaveMenu {
        overflow: auto;
        display: flex;
        flex-direction: column;
        height: 100%;
        padding: 0px 0 0 14px;
    }
    &.toggled-sideNav {
        min-width: 80px;
        width: 80px;
        .bloc-MenuSideNav {
            overflow-x: hidden;
        }
        .title-item-menu,
        .name-profile {
            opacity: 0 !important;
            transition-delay: 0.2s !important;
        }
        .motif-sideNaveMenu {
            img {
                width: 50px;
            }
        }
    }
    a {
        color: #95b5ff;
    }
    .logo {
        min-height: 133px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 20px 13px;
        background-color: #fff;
        a {
            overflow: auto;
        }
    }
    .bloc-MenuSideNav {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
        margin-bottom: calc(var(--PX) * 22);
        position: relative;
        .menuSide {
            margin-bottom: 100px;
        }
        &:before {
            content: "";
            position: absolute;
            top: 0;
            left: 5px;
            right: 22px;
            height: 1px;
            opacity: 0.2;
            background-color: #ffff;
        }
        .menuSide {
            margin-bottom: 40px;
        }
    }
    .group-side-menu {
        list-style: none;
        padding: 10px 0;
        margin-bottom: 0;
        position: relative;
        &:before {
            content: "";
            position: absolute;
            bottom: 0;
            left: 5px;
            right: 22px;
            height: 1px;
            background-color: #ffff;
            opacity: 0.2;
            z-index: 1;
        }
        &:last-child {
            &:before{
                content: none;
            }
        }
        li {
            &.nav-link-demande {
                a {
                    .title-item-menu {
                        opacity: 1;
                        color: ${DefaultLabelColor};
                    }
                    svg {
                        path {
                            fill: ${DefaultLabelColor};
                            opacity: 1;
                        }
                    }
                    &.active {
                        background-color: transparent;
                        &::before,
                        &:after {
                            content: none;
                        }
                    }
                }
            }
            &.nav-link-aide {
                a {
                    &:hover {

                        color: ${DefaultSecondaryColor};
                        .title-item-menu {
                            opacity: 1;
                            color: ${DefaultSecondaryColor} !important;
                        }
                        svg {
                            path {
                                fill: ${DefaultSecondaryColor};
                                opacity: 1;
                            }
                        }
                    }
                    &.active {
                        background-color: ${DefaultSecondaryColor};
                        &::before {
                            box-shadow: 7.5px 7.5px 0 7.5px ${DefaultSecondaryColor};
                        }
                        &::after {
                            box-shadow: 7.5px -7.5px 0 7.5px ${DefaultSecondaryColor};
                        }
                        svg {
                            path {
                                fill: ${DefaultPrimaryColor};
                                opacity: 1;
                            }
                        }
                    }
                }
            }
            a {
                padding: 18px 15px;
                position: relative;
                color: #95b5ff;
                text-decoration: none;
                display: flex;
                border-top-left-radius: 100px;
                border-bottom-left-radius: 100px;
                font-weight: 600;
                font-size: 14px;
                line-height: 21px;
                transition: 0.4s ease-in-out;
                .title-item-menu {
                    display: block;
                    text-align: start;
                    white-space: nowrap;
                    margin-left: 12px;
                    color: #FFF;
                    transition: 0.4s ease-in-out;
                }
                .icon-side {
                    display: block;
                    min-width: 28px;
                }
                svg {
                    vertical-align: middle;
                    position: relative;
                    top: -2px;
                    path {
                        fill: #E0BEB7;
                        transition: 0.4s ease-in-out;
                    }
                }
                &:hover {
                    .title-item-menu {
                        color: ${DefaultLabelColor};
                    }
                    svg {
                        path {
                            fill: ${DefaultLabelColor};
                        }
                    }
                }
                &.active {
                    background-color: #f5f5fa;
                    color: ${DefaultPrimaryColor};
                    font-weight: 600;
                    .title-item-menu {
                        color: ${DefaultPrimaryColor};
                    }
                    svg {
                        path {
                            fill: ${DefaultPrimaryColor};
                        }
                    }
                    &::before {
                        content: "";
                        position: absolute;
                        top: -20px;
                        right: 0;
                        width: 20px;
                        height: 20px;
                        background-color: transparent;
                        border-bottom-right-radius: 10px;
                        box-shadow: 7.5px 7.5px 0 7.5px #f5f5fa;
                    }
                    &::after {
                        content: "";
                        position: absolute;
                        bottom: -20px;
                        right: 0;
                        width: 20px;
                        height: 20px;
                        background-color: transparent;
                        border-top-right-radius: 10px;
                        box-shadow: 7.5px -7.5px 0 7.5px #f5f5fa;
                    }
                }
            }
        }
    }
    .option-profile {
        color: #fff;
        display: flex;
        padding: 6px 10px;
        text-align: left;
        align-items: center;
        background-color: transparent !important;
        border: 0 !important;
        outline: none !important;
        box-shadow: none !important;
        &::after {
            border-top: 0.4em solid;
            border-right: 0.4em solid transparent;
            border-left: 0.4em solid transparent;
            position: relative;
            top: 3px;
        }
        .name-profile {
            display: block;
            text-align: start;
            white-space: nowrap;
            margin-left: 20px;
            max-width: 112px;
            overflow: hidden;
            text-overflow: ellipsis;
        }
        .icon-profile {
            min-width: 31px;
            width: 31px;
            height: 31px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            background-color: ${DefaultSecondaryColor};
            svg {
                vertical-align: middle;
                position: relative;
                top: -2px;
                [fill="#2146BD"] {
                    fill: ${DefaultPrimaryColor};
                }
                path {
                    transition: 0.4s ease-in-out;
                }
            }
        }
    }
    .toggle-menu {
        position: absolute;
        top: 47px;
        right: -10px;
        z-index: 10;
        background: #F8E8E5;
        border-radius: 7px;
        width: 24px;
        height: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 18px;
        color: #8381ba;
        cursor: pointer;
        svg {
            font-size: 20px;
            path{
                fill: ${DefaultPrimaryColor}
            }
        }
        @media(max-width: 993px){
            svg {
                path{
                    fill: rgb(255, 255, 255);
                }
            }
        }
    }
    .option-user-side-menu {
        padding-bottom: 15px;
        & > .dropdown {
            .dropdown-menu {
                background: #ffffff;
                border-radius: 12px;
                width: calc(100% - 18px);
            }
            .dropdown-item {
                color: #1c1d21;
                transition: 0.3s ease-in-out;
                background-color: transparent !important;
                padding: 5px 20px;
                &:hover {
                    color: ${DefaultPrimaryColor};
                }
            }
        }
    }
    .motif-sideNaveMenu {
        padding-right: 18px;
        text-align: center;
        margin-bottom: 8px;
        img {
            transition: 0.3s ease-in-out;
            width: 113px;
        }
    }
    @media (max-width: 993px) {
        background-color: transparent;
        position: fixed;
        z-index: 9999;
        top: 0;
        bottom: 0;
        left: -100%;
        transition: 0.4s ease-in-out;
        width: 235px;
        .toggle-menu {
            transition: 0.4s ease-in-out;
            width: 50px;
            height: 50px;
            min-width: 50px;
            background-color: #b7b7b9;
            border: 0;
            border-radius: 8px;
            display: flex;
            align-items: center;
            justify-content: center;
            opacity: 0;
            pointer-events: none;
            position: fixed;
            right: -100%;
            top: 30px;
            z-index: 9999;
            svg {
                font-size: 36px;
                color: #fff;
            }
        }
        .bloc-sideNaveMenu {
            padding: 0;
        }
        .bloc-MenuSideNav {
            height: 100%;
            &:before {
                content: none;
            }
            .menuSide {
                margin: 0;
                position: relative;&:before {
                content: "";
                position: absolute;
                top: 100%;
                left: 0;
                right: 0;
                background-color: ${DefaultPrimaryColor};
                height: calc(100vh - 133px - var(--height-menuSide));
            }
            }
        }
        .option-user-side-menu {
            background-color: ${DefaultPrimaryColor};
            margin: 0;
            padding: 60px 0 20px;
            height: 100%;
            & > .dropdown {
                padding-left: 18px;
            }
        }
        .motif-sideNaveMenu {
            padding-right: 0;
        }
        .group-side-menu {
            padding: 0px;
            padding-bottom: 1px;
            &:before {
                background-color: #95b5ff;
                opacity: 0.3;
                left: 20px;
                right: 20px;
            }
            &:after {
                content: "";
                position: absolute;
                bottom: 0;
                left: 0;
                right: 0;
                height: 1px;
                background: ${DefaultPrimaryColor};
            }
            li {
                background-color: ${DefaultPrimaryColor};
                padding: 0px 0 0px 14px;
                margin: 10px 0;
                position: relative;
                &:first-child {
                    &:before {
                        content: "";
                        position: absolute;
                        top: -10px;
                        left: 0;
                        right: 0;
                        height: 10px;
                        background-color: ${DefaultPrimaryColor};
                    }
                }
                &:first-child {
                    &.parent-active {
                        &:before {
                            content: "";
                            position: absolute;
                            top: -10px;
                            left: 0;
                            right: 0;
                            height: 10px;
                            background-color: ${DefaultPrimaryColor};
                            border-bottom-right-radius: 15px;
                        }
                    }
                }
                &:not(.previous){
                    &:after {
                        content: "";
                        position: absolute;
                        bottom: -10px;
                        left: 0;
                        right: 0;
                        height: 10px;
                        background-color: ${DefaultPrimaryColor};
                        z-index: -1;
                    }
                }
                &:last-child {
                    &.parent-active {
                        &:after {
                            content: "";
                            position: absolute;
                            bottom: -10px;
                            left: 0;
                            right: 0;
                            height: 10px;
                            background-color: ${DefaultPrimaryColor};
                            z-index: -1;
                            border-top-right-radius: 15px;
                        }
                    }
                }
                a {
                    padding: 14px 15px;
                    &.active {
                        border-radius: 100px;
                        &::before,
                        &::after {
                            content: none;
                        }
                    }
                }
                &.prev-active {
                    border-bottom-right-radius: 15px;
                }
                &.next-active {
                    border-top-right-radius: 15px;
                }
                &.parent-active {
                    border-radius: 0 100px 100px 0;
                    padding: 0px 0 0px 14px;
                    position: relative;
                    &:before {
                        content: "";
                        position: absolute;
                        top: -10px;
                        left: 0px;
                        right: 0px;
                        height: 10px;
                        background-color: ${DefaultPrimaryColor};
                        border-bottom-right-radius: 15px;
                    }
                    &:after {
                        content: "" !important;
                        position: absolute;
                        bottom: -10px;
                        left: 0px;
                        right: 0px;
                        height: 10px;
                        background-color: ${DefaultPrimaryColor};
                        z-index: -1;
                        border-top-right-radius: 15px;
                    }
                }
            }
        }
    }
`;
