export const PUBLIC_ROUTE = {
  LOGIN: { url: "/connexion", roles: [] },
  REGISTER: { url: "/inscription", roles: [] },
  FORGOTPASSWORD: { url: "/mot-de-passe-oublie", roles: [] },
  RESETPASSWORD: { url: "/reinitialisation-du-mot-de-passe", roles: [] },
  VERIFYEMAIL: { url: "/verifier-email", roles: [] },
  NOTFOUND: { url: "/404", roles: [] },
};

export const PRIVATE_ROUTE = {
  HOME: { url: "/", roles: ['ROLE_CLIENT','ROLE_ADMIN'] },
  COMMANDES: { url: "/commandes", roles: ['ROLE_CLIENT','ROLE_ADMIN'] },
  DEVIS: { url: "/devis", roles: ['ROLE_CLIENT'] },
  CLIENTS: { url: "/clients", roles: ['ROLE_ADMIN'] },
  DEVIS_ADD: { url: "/nouveau-devis", roles: ['ROLE_CLIENT'] },
  CGU: { url: "/conditions-generales-d-utilisation", roles: ['ROLE_CLIENT', 'ROLE_ADMIN'] },
};