import styled from "styled-components";
import { Button } from "@mui/material";
import { Form } from "react-bootstrap";
import { Modal } from "react-bootstrap";
import { DefaultPrimaryColor, DefaultPrimaryColorLight, mixinIcon } from "../variables"; 
import arrowDef from "../../images/icons/arrow-def.svg";
import bgError from "../../images/bg-error.png";
import bgSuccess from "../../images/bg-success.png";

export const BlocAlert = styled.div`
    padding: 14px;
    display: flex;
    font-weight: 600;
    font-size: 15px;
    line-height: 21px;
    margin-bottom: 25px;
    border: 0;
    border-radius: 6px;
    &.login-alert {
        background-color: #fff !important;
    }
    svg {
        font-size: 18px;
    }
    .icon-type-alert {
        margin-right: 8px;
        position: relative;
        top: -1px;
    }
    &.success {
        background: url(${bgSuccess});
        background-repeat: repeat-x;
        color: #37a143;
        background-size: contain;
        svg {
            color: #37a143;
        }
    }
    &.error {
        background: url(${bgError});
        background-repeat: repeat-x;
        color: #dd7141;
        background-size: contain;
        svg {
            color: #dd7141;
        }
    }
`;
export const ButtenDef = styled(Button)`
    &.btn-default {
        text-transform: initial;
        height: 46px;
        padding: 14px 24px;
        font-size: 15px;
        font-family: "Open Sans";
        font-weight: 600;
        background-color: ${DefaultPrimaryColor};
        color: #fff;
        border-radius: 23px;
        border: 1px solid ${DefaultPrimaryColor};
        &:hover {
            background-color: transparent;
            color: ${DefaultPrimaryColor};
        }
    }
    & > svg {
        margin-right: 10px;
        font-size: 17px;
    }
    &.light-btn {
        background-color: #fff;
        border: 1px solid #000;
        color: #000;
        &:hover {
            background-color: #fff;
            color: #000;
        }
    }
    &.light-bleu-btn {
        background-color: #fff;
        border: 1px solid ${DefaultPrimaryColor};
        color: ${DefaultPrimaryColor};
        .MuiTouchRipple-root {
            display: none;
        }
        &:hover {
            background-color: ${DefaultPrimaryColor};
            color: #fff;
        }
    }
    &.delete-btn {
        &:hover {
            border-color: #ff808b;
            color: #ff808b;
        }
    }
    &.dark-bleu-btn {
        background-color: ${DefaultPrimaryColor};
        border: 1px solid ${DefaultPrimaryColor};
        color: #fff;
        &:hover {
            background-color: transparent;
            color: ${DefaultPrimaryColor};
        }
    }
`;

export const BlocPagination = styled.div`
    margin-top: 30px;
    .sort-count-page {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        & > span {
            display: block;
            margin-bottom: 10px;
            font-weight: 600;
            font-size: 14px;
            color: #949494;
            letter-spacing: 0.21px;
            margin-right: 16px;
        }
        .select-multi-values {
            margin-bottom: 10px;
        }
        .react-select__control {
            height: 40px;
            min-width: 62px;
            background: #ffffff;
            border: 1px solid #ececf2;
            border-radius: 20px;
            &.react-select__control--menu-is-open{
                border-bottom: 1px solid #ececf2;
            }
        }
        .react-select__indicator {
            padding: 8px !important;
            padding-left: 0 !important;
            opacity: .5;
        }
        .react-select__value-container {
            padding: 0 10px;
        }
        .react-select__single-value {
        }
        .react-select__menu{
            margin: 3px 0;
            padding: 0;
        }
    }
    .detail-page-index {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        .total-pagination {
            display: flex;
            align-items: center;
            font-weight: 600;
            font-size: 14px;
            color: #1c1d21;
            letter-spacing: 0.21px;
            text-align: center;
            margin-bottom: 10px;
            & > span {
                display: inline-block;
                padding: 0 18px;
                font-weight: 700;
                color: ${DefaultPrimaryColor};
                &:last-child {
                    color: #8181a5;
                }
            }
        }
        .nav-pagination {
            display: flex;
            align-items: center;
            margin-bottom: 10px;
            .btn-default {
                width: 32px;
                min-width: 32px;
                height: 32px;
                padding: 0;
                display: flex;
                justify-content: center;
                align-items: center;
                border: 1px solid ${DefaultPrimaryColor};
                border-radius: 50%;
                color: ${DefaultPrimaryColor};
                margin-right: 12px;
                &:last-child {
                    margin-right: 0px;
                }
                &:disabled {
                    opacity: 0.6;
                }
                svg {
                    margin: 0;
                    font-size: 15px;
                }
                &:hover {
                    color: #ffffff;
                    background-color: ${DefaultPrimaryColor};
                }
            }
        }
    }
`;

export const ModalDef = styled(Modal)`
    &.modal-def {
        font-family: "Open Sans";
        .close-modal {
            border: 1px solid #ececf2;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 30px;
            height: 30px;
            min-width: 30px;
            border-radius: 8px;
            cursor: pointer;
            background-color: #f8f8f8;

            svg {
                font-size: 16px;
                color: #8181a5;
            }
        }
        .blockBtnModalConfirm {
            text-align: center;
            display: flex;
            & > button {
                margin: 0 10px;
                width: 100%;
                &.light-btn{
                    &:hover{
                        background-color: rgb(227 195 188);
                        border-color: rgb(227 195 188);
                    }
                }
            }
        }
        .modal-header {
            display: flex;
            align-items: center;
            justify-content: space-between;
            border: 0;
            padding: 30px 40px;
            position: sticky;
            top: 0;
            z-index: 11;
            background-color: #fff;
            transition: 0.2s ease;

            &.is-scroll {
                box-shadow: 0 6px 4px 0 rgba(0, 0, 0, 0.03);
            }

            .modal-title {
                color: ${DefaultPrimaryColor};
                font-size: 25px;
                line-height: 27px;
                margin-bottom: 0;
            }
            @media (max-width: 993px) {
                padding: 20px 15px;
            }
        }

        .modal-body {
            padding: 0 40px;
            height: 100%;
            overflow: auto;
            label {
                color: #000;
                font-weight: 600;
            }
            .wysiwyg-default {
                font-size: 15px;
                a {
                    color: ${DefaultPrimaryColor};
                    &:hover {
                        text-decoration: none;
                    }
                }
                ul {
                    list-style: none;
                    padding-left: 20px;
                    li {
                        display: flex;
                        align-items: flex-start;
                        margin-bottom: 8px;
                        &:before {
                            content: "";
                            position: relative;
                            top: 6px;
                            width: 6px;
                            height: 6px;
                            border-radius: 50%;
                            background-color: ${DefaultPrimaryColor};
                            margin-right: 10px;
                        }
                    }
                }
                ol {
                    list-style: none;
                    counter-reset: li;
                    margin-bottom: 8px;
                    li {
                        counter-increment: li;
                        &::before {
                            content: counter(li) ".";
                            color: ${DefaultPrimaryColor};
                            display: inline-block;
                            margin-left: -18px;
                            margin-right: 6px;
                        }
                    }
                }
            }
            @media (max-width: 993px) {
                padding: 0px 15px;
            }
            .detail-group-rooms {
                @media (min-width: 768px) {
                    & > .row {
                        .col-md-4 {
                            width: 43%;
                        }
                        .col-md-8 {
                            width: 57%;
                        }
                    }
                }
            }
        }

        .modal-dialog {
            max-width: 90%;
            width: 565px;
            overflow: hidden;
            margin: 40px auto 1.75rem;
            border-radius: 19px;
            .titre-bloc-item {
                font-weight: 700;
                font-size: 18px;
                line-height: 27px;
                margin-bottom: 13px;
            }
            .bloc-detail-info-room {
                padding-left: 0;
            }
            .modal-content {
                max-height: calc(100vh - 70px);
                background: #ffffff;
                box-shadow: 0 8px 21px 0 rgba(153, 155, 168, 0.1);
                border: 0;
                padding-bottom: 30px;
            }
        }
    }
`;

export const CheckBoxForm = styled.div`
    margin-bottom: 30px;
    &.disabled-checkbox {
        .bloc-check-box {
            pointer-events: none;
        }
    }
    & > label {
        margin-bottom: 16px;
        font-size: 14px;
        color: #8181a5;
        line-height: 21px;
        display: flex;
        i {
            font-style: normal;
        }
    }
    &.inline-checkbox {
        .bloc-check-box {
            display: flex;
            flex-wrap: wrap;
        }
    }
    .check-box-form {
        display: flex;
        flex-wrap: wrap;
        padding: 0;
        margin-bottom: 17px;
        margin-right: 60px;
        &.form-error {
            & > input {
                border: 1px solid #f7f8ff;
                background-color: #ffe7e7;
            }
        }
        & > label {
            position: relative;
            font-size: 14px;
            line-height: 20px;
            text-transform: inherit;
            margin-bottom: 0;
            padding-left: 0px;
            display: flex;
            cursor: pointer;
            &:after {
                content: "";
                position: absolute;
                top: 5px;
                left: 5px;
                width: 10px;
                height: 10px;
                z-index: 1;
                opacity: 0;
                background-color: ${DefaultPrimaryColor};
                transition: 0.1s ease-in-out;
            }
            &:before {
                content: "";
                position: relative;
                display: inline-block;
                border: 1px solid ${DefaultPrimaryColor};
                border-radius: 0;
                background: #fff;
                height: 20px;
                width: 20px;
                min-width: 20px;
                margin-right: 11px;
                transition: 0.1s ease-in-out;
            }
        }
        & > input {
            appearance: none;
            position: relative;
            top: -3px;
            height: 20px;
            width: 20px;
            min-width: 20px;
            border: 0;
            margin: 0;
            border: 1px solid #e1dcdc;
            border-radius: 4px;
            transition: 0.2s ease-in-out;
            outline: none;
            cursor: pointer;
            display: none;
            &:focus {
                box-shadow: none;
            }
            &:checked {
                & + label {
                    &:after {
                        opacity: 1;
                    }
                }
            }
            &:disabled {
                cursor: no-drop;
            }
        }
    }
    .details-checkbox {
        padding: 20px 0 13px 32px;
        width: 100%;
    }
`;

export const RadioButtonForm = styled.div`
    label {
        font-size: 14px;
        margin-bottom: 14px;
        color: #8181a5;
        display: flex;
        i {
            font-style: normal;
        }
    }
    .bloc-radios-button {
        display: flex;
        flex-wrap: wrap;
    }
    &:not(.radio-icon-button) {
        margin-bottom: 30px;
        .radio-button-form {
            display: flex;
            padding: 0;
            margin-right: 24px;
            label {
                color: #000;
                font-size: 14px;
                text-transform: inherit;
                margin-bottom: 0;
                padding-left: 12px;
            }
            & > input {
                appearance: none;
                position: relative;
                top: 1px;
                height: 20px;
                width: 20px;
                min-width: 20px;
                border: 0;
                margin: 0;
                border-radius: 50%;
                background: #FFF;
                border: 1px solid ${DefaultPrimaryColor};
                transition: 0.2s ease-in-out;
                outline: none;
                cursor: pointer;
                &:focus {
                    box-shadow: none;
                }
                &:checked {
                    background: ${DefaultPrimaryColor};
                    -webkit-box-shadow: inset 0px 0px 0px 3px #fff;
                    box-shadow: inset 0px 0px 0px 3px #fff;
                }
                &:disabled {
                    cursor: no-drop;
                }
            }
        }
    }
    &.radio-icon-button {
        .bloc-radios-button {
            margin: 0 -7px;
        }
        .radio-button-form {
            width: 25%;
            min-width: 25%;
            height: 137px;
            padding: 0 7px;
            input {
                display: none;
                &:checked {
                    & ~ label {
                        background-color: ${DefaultPrimaryColor};
                        color: #fff;
                        .icon-radio {
                            #EditUser,
                            #NewUser,
                            #PhoneUser,
                            #GuichetUser,
                            #CourrierUser {
                                fill: #eef2fd;
                                stroke: ${DefaultPrimaryColor};
                            }
                            #CourrielUser {
                                #Group-34 {
                                    path {
                                        fill: #eef2fd;
                                        stroke: ${DefaultPrimaryColor};
                                    }
                                }
                                #att {
                                    path {
                                        stroke: #eef2fd;
                                    }
                                }
                            }
                        }
                    }
                }
            }
            label {
                color: ${DefaultPrimaryColor};
                text-align: center;
                background-color: #eef2fd;
                border-radius: 15px;
                height: 100%;
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                justify-content: center;
                font-weight: 900;
                cursor: pointer;
                .label-radio {
                    display: block;
                    width: 100%;
                    text-transform: uppercase;
                    font-size: 12px;
                    letter-spacing: 0.2px;
                }
                .icon-radio {
                    display: inline-block;
                    margin-bottom: 10px;
                    min-height: 50px;
                }
            }
        }
    }
    &.form-error {
        &:not(.radio-icon-button) {
            .radio-button-form {
                & > input {
                    background: #ffe3e3;
                }
            }
        }
    }
`;

export const InputGroup = styled(Form.Control)`
    height: 46px;
    padding: 6px 20px;
    background-color: #F7F7F7;
    border-radius: 8px 8px 0 0;
    border: 0;
    font-size: 14px;
    font-weight: 400;
    border-bottom: 1px solid #d8d8d8;
    color: #000;
    &.input-num {
        -moz-appearance: textfield;
        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }
    }
    &::-webkit-input-placeholder {
        font-size: 14px;
        color: #8181a5;
    }
    &::-moz-placeholder {
        font-size: 14px;
        color: #8181a5;
    }
    &:-ms-input-placeholder {
        font-size: 14px;
        color: #8181a5;
    }
    &:-moz-placeholder {
        font-size: 14px;
        color: #8181a5;
    }
    &:focus {
        background: #f7f8ff;
        border-bottom: 1px solid ${DefaultPrimaryColor};
        box-shadow: none;
        outline: none;
    }
`;

export const DatePickerStyle = styled.div`
    .datePickerCalender {
        position: relative;
        & > i {
            position: absolute;
            top: 16px;
            right: 10px;
            pointer-events: none;
        }
        .rmdp-container {
            display: block !important;
            height: auto !important;
        }
    }
    &.isOpen {
        .react-datetime-picker {
            &.react-datetime-picker--closed {
                .react-datetime-picker__wrapper {
                    background: #f7f8ff;
                    border-bottom: 1px solid ${DefaultPrimaryColorLight};
                    box-shadow: none;
                }
            }
        }
    }
    label {
        font-size: 14px;
        margin-bottom: 14px;
        padding: 0;
        font-weight: 500;
        .info-label {
            display: block;
            font-size: 13px;
            line-height: 19px;
            color: #748993;
            margin-top: 11px;
            font-weight: 400;
        }
    }

    .react-date-picker {
        margin-bottom: 20px;
        display: flex;
        min-width: 200px;
        .react-date-picker__inputGroup__year {
            min-width: 40px;
        }
        &.react-date-picker--open {
            .react-date-picker__wrapper {
                background: #f7f8ff;
                border-bottom: 1px solid ${DefaultPrimaryColorLight};
                box-shadow: none;
            }
        }
        &:before {
            content: "";
            display: inline-block;
            width: 0;
            height: 0;
            border-left: 8px solid transparent;
            border-right: 8px solid transparent;

            border-bottom: 8px solid white;

            position: absolute;
            bottom: -15px;
            right: 18px;
            z-index: 5;
        }

        .react-calendar {
            border: none;
            border-radius: 15px;
            overflow: hidden;
            box-shadow: 0 2px 9px 0 rgba(182, 172, 251, 0.42);
            margin-top: 15px;
            font-family: "Open Sans";
            .react-calendar__navigation {
                margin: 0;
                border-bottom: 1px solid #f3f3f3;
                .react-calendar__navigation__arrow {
                    color: #c9c9c9;
                }
                .react-calendar__navigation__label__labelText {
                    &.react-calendar__navigation__label__labelText--from {
                        color: ${DefaultPrimaryColor};
                    }
                }

                button[disabled] {
                    background: none;
                    cursor: not-allowed;
                    opacity: 0.5;
                }
            }

            .react-calendar__month-view__weekdays {
                padding: 7px 0;
                border-bottom: 1px solid #f3f3f3;
                color: #999999;
                font-size: 13px;
                font-weight: normal;
                abbr {
                    text-decoration: none;
                }
            }

            .react-calendar__month-view__days__day {
                color: #000000;
                font-size: 13px;
                padding: 5px;

                abbr {
                    padding: 5px;
                    display: block;
                    //background: red;
                    border-radius: 5px;
                }
            }

            .react-calendar__month-view__days__day--weekend {
                color: #000000;
            }

            .react-calendar__month-view__days__day--neighboringMonth {
                color: #999999;
            }

            .react-calendar__tile {
                &:disabled {
                    background: none;
                    &.react-calendar__month-view__days__day {
                        color: #999999;
                        cursor: not-allowed;
                        opacity: 0.5;
                    }
                }

                &:enabled:hover,
                &:enabled:focus {
                    background: none;
                    abbr {
                        background: #e6e6e6;
                    }
                }

                &.react-calendar__tile--now {
                    background: none;
                    abbr {
                        background: #f7f8ff;
                        color: #8181a5;
                    }
                }

                &.react-calendar__tile--active {
                    &,
                    &:hover,
                    &:focus {
                        background: none;
                        abbr {
                            background: ${DefaultPrimaryColor};
                            color: #fff;
                        }
                    }
                }
            }
        }
        .react-date-picker__calendar {
            z-index: 50;
        }
        .react-date-picker__wrapper {
            font-family: "Open Sans";
            height: 46px;
            padding: 6px 45px 6px 20px;
            background: #f9f9fc;
            border-radius: 8px 8px 0 0;
            border: 0;
            font-size: 16px;
            font-weight: 400;
            border-bottom: 1px solid #d8d8d8;
            color: #000;
            position: relative;
            overflow: hidden;
            .react-date-picker__calendar-button.react-date-picker__button {
                position: absolute;
                top: 10px;
                right: 5px;
                height: 30px;

                border-radius: 25px;

                width: 42px;
                display: flex;
                align-items: center;
                justify-content: center;
            }
            .react-date-picker__inputGroup__input {
                color: #000;
                &::-webkit-input-placeholder {
                    font-size: 13px;
                    color: #8181a5;
                }
                &::-moz-placeholder {
                    font-size: 13px;
                    color: #8181a5;
                }
                &:-ms-input-placeholder {
                    font-size: 13px;
                    color: #8181a5;
                }
                &:-moz-placeholder {
                    font-size: 13px;
                    color: #8181a5;
                }

                &:focus,
                &:invalid {
                    background: none;
                    outline: none;
                }
            }

            .react-date-picker__inputGroup__divider {
                color: #8181a5;
            }
        }
    }

    .react-date-picker--disabled {
        background: none;
        .react-date-picker__wrapper {
            background-color: #e9ecef;
        }
    }

    @media (max-width: 768px) {
        .react-date-picker__calendar {
            width: 100%;
        }
        .react-calendar {
            width: 100%;
        }
    }

    .react-datetime-picker {
        margin-bottom: 20px;
        display: flex;
        min-width: 200px;
        &.react-datetime-picker--open {
            .react-datetime-picker__wrapper {
                background: #f7f8ff;
                border-bottom: 1px solid ${DefaultPrimaryColorLight};
                box-shadow: none;
            }
        }
        &:before {
            content: "";
            display: inline-block;
            width: 0;
            height: 0;
            border-left: 8px solid transparent;
            border-right: 8px solid transparent;

            border-bottom: 8px solid white;

            position: absolute;
            bottom: -15px;
            right: 18px;
            z-index: 100;
        }

        .react-calendar {
            border: none;
            border-radius: 15px;
            overflow: hidden;
            box-shadow: 0 2px 9px 0 rgb(182 172 251 / 42%);
            margin-top: 15px;

            .react-calendar__navigation {
                margin: 0;
                border-bottom: 1px solid #f3f3f3;
                .react-calendar__navigation__arrow {
                    color: #c9c9c9;
                }
                .react-calendar__navigation__label__labelText {
                    &.react-calendar__navigation__label__labelText--from {
                        color: ${DefaultPrimaryColor};
                    }
                }

                button[disabled] {
                    background: none;
                    cursor: not-allowed;
                    opacity: 0.5;
                }
            }

            .react-calendar__month-view__weekdays {
                padding: 7px 0;
                border-bottom: 1px solid #f3f3f3;
                color: #999999;
                font-size: 13px;
                font-weight: normal;
                abbr {
                    text-decoration: none;
                }
            }

            .react-calendar__month-view__days__day {
                color: #000000;
                font-size: 13px;
                padding: 5px;

                abbr {
                    padding: 5px;
                    display: block;
                    //background: red;
                    border-radius: 5px;
                }
            }

            .react-calendar__month-view__days__day--weekend {
                color: #000000;
            }

            .react-calendar__month-view__days__day--neighboringMonth {
                color: #999999;
            }

            .react-calendar__tile {
                &:disabled {
                    background: none;
                    &.react-calendar__month-view__days__day {
                        color: #999999;
                        cursor: not-allowed;
                        opacity: 0.5;
                    }
                }

                &:enabled:hover,
                &:enabled:focus {
                    background: none;
                    abbr {
                        background: #e6e6e6;
                    }
                }

                &.react-calendar__tile--now {
                    background: none;
                    abbr {
                        background: #f7f8ff;
                        color: #8181a5;
                    }
                }

                &.react-calendar__tile--active {
                    &,
                    &:hover,
                    &:focus {
                        background: none;
                        abbr {
                            background: ${DefaultPrimaryColor};
                            color: #fff;
                        }
                    }
                }
            }
        }
        .react-datetime-picker__calendar {
            z-index: 50;
        }
        .react-datetime-picker__wrapper {
            font-family: "Open Sans";
            height: 46px;
            padding: 6px 45px 6px 20px;
            background: #f9f9fc;
            border-radius: 8px 8px 0 0;
            border: 0;
            font-size: 16px;
            font-weight: 400;
            border-bottom: 1px solid #d8d8d8;
            color: #000;
            position: relative;
            overflow: hidden;
            .react-datetime-picker__calendar-button.react-datetime-picker__button {
                position: absolute;
                top: 10px;
                right: 5px;
                height: 30px;

                border-radius: 25px;

                width: 42px;
                display: flex;
                align-items: center;
                justify-content: center;
            }
            .react-datetime-picker__inputGroup__input {
                color: #000;
                &::-webkit-input-placeholder {
                    font-size: 15px;
                    color: #8181a5;
                }
                &::-moz-placeholder {
                    font-size: 15px;
                    color: #8181a5;
                }
                &:-ms-input-placeholder {
                    font-size: 15px;
                    color: #8181a5;
                }
                &:-moz-placeholder {
                    font-size: 15px;
                    color: #8181a5;
                }

                &:focus,
                &:invalid {
                    background: none;
                    outline: none;
                }
            }

            .react-datetime-picker__inputGroup__divider {
                color: #8181a5;
            }
        }
    }

    .react-datetime-picker--disabled {
        background: none;
    }
    .react-datetime-picker__clock {
        border: 0;
        border-radius: 50%;
        width: 150px;
        height: 150px;
        inset: auto 65% 100% 0 !important;
        time {
            height: 122px !important;
            width: 122px !important;
        }
    }
    @media (max-width: 768px) {
        .react-datetime-picker__calendar {
            width: 100%;
        }
        .react-calendar {
            width: 100%;
        }
    }
    .rmdp-container {
        .rmdp-input {
            font-family: "Open Sans";
            height: 46px;
            padding: 6px 45px 6px 20px;
            background: #f9f9fc;
            border-radius: 8px 8px 0 0;
            border: 0;
            font-size: 16px;
            font-weight: 400;
            border-bottom: 1px solid #d8d8d8;
            color: #000;
            position: relative;
            overflow: hidden;
            width: 100%;
            &:focus {
                border: 0;
                background: #f7f8ff;
                border-bottom: 1px solid ${DefaultPrimaryColorLight};
                box-shadow: none;
            }
        }
        .rmdp-ep-shadow:after {
            box-shadow: 0 0 6px #8798ad36;
        }
        .rmdp-wrapper {
            border: none;
            border-radius: 15px;
            overflow: hidden;
            box-shadow: 0 2px 9px 0 rgba(182, 172, 251, 0.42);
            width: 350px;
            max-width: 100vw;
            .rmdp-top-class,
            .rmdp-day-picker {
                display: block !important;
            }
            .rmdp-arrow-container {
                align-items: center;
                &:hover {
                    background-color: transparent;
                    box-shadow: none;
                    .rmdp-arrow {
                        border: solid #0074d9;
                    }
                }
                &.disabled {
                    .rmdp-arrow {
                        border-color: gray !important;
                    }
                    &:hover {
                        .rmdp-arrow {
                            border-color: gray !important;
                        }
                    }
                }
            }
            .rmdp-arrow {
                border: solid #0074d9;
                border-width: 0 1px 1px 0 !important;
                height: 2px;
                margin-top: 0px;
                padding: 2px;
                width: 2px;
            }
            .rmdp-month-picker,
            .rmdp-year-picker {
                .rmdp-ym {
                    .rmdp-day {
                        &.rmdp-selected {
                            span {
                                &:not(.highlight) {
                                    background-color: transparent !important;
                                    box-shadow: none;
                                    color: ${DefaultPrimaryColor};
                                }
                            }
                        }
                    }
                }
            }
            .rmdp-day {
                &.rmdp-selected {
                    span {
                        &:not(.highlight) {
                            background-color: ${DefaultPrimaryColor} !important;
                        }
                    }
                }
                span {
                    border-radius: 5px;
                    font-size: 13px;
                    position: static;
                    display: flex;
                    height: 100%;
                    width: 100%;
                    align-items: center;
                }
                &:not(.rmdp-disabled) {
                    &:not(.rmdp-day-hidden) {
                        span {
                            &:hover {
                                background-color: #e6e6e6;
                            }
                        }
                    }
                }
            }
            .rmdp-day-picker {
                & > div {
                    & > div {
                        &:first-child {
                            padding: 7px 0;
                            border-bottom: 1px solid #f3f3f3;
                            border-top: 1px solid #f3f3f3;
                            .rmdp-week-day {
                                color: #999999;
                                font-size: 13px;
                                font-weight: normal;
                                height: auto;
                            }
                        }
                    }
                }
            }
        }
    }
    .datepicker-model3 {
        .form-control {
            font-family: "Open Sans";
            height: 46px;
            padding: 6px 20px;
            background-color: #f9f9fc;
            border-radius: 8px 8px 0 0;
            border: 0;
            font-size: 16px;
            font-weight: 400;
            border-bottom: 1px solid #d8d8d8;
            color: #000;
            background-image: none;
            &.input-num {
                -moz-appearance: textfield;
                &::-webkit-outer-spin-button,
                &::-webkit-inner-spin-button {
                    -webkit-appearance: none;
                    margin: 0;
                }
            }
            &::-webkit-input-placeholder {
                font-size: 14px;
                color: #8181a5;
            }
            &::-moz-placeholder {
                font-size: 14px;
                color: #8181a5;
            }
            &:-ms-input-placeholder {
                font-size: 14px;
                color: #8181a5;
            }
            &:-moz-placeholder {
                font-size: 14px;
                color: #8181a5;
            }
            &:focus {
                background: #f7f8ff;
                border-bottom: 1px solid ${DefaultPrimaryColorLight};
                box-shadow: none;
                outline: none;
            }
            &:disabled{
                background-color: #e9ecef;
            }
        }
        .react-datepicker-popper {
            left: 0 !important;
            right: 0px !important;
        }
        .react-datepicker__month-container {
            width: 100%;
        }
        .react-datepicker {
            border: none;
            border-radius: 15px;
            overflow: hidden;
            box-shadow: 0 2px 9px 0 rgba(182, 172, 251, 0.42);
            width: 100%;
            font-size: 13px;
            font-family: "Open Sans";
            line-height: 37px;
        }
        .react-datepicker__header {
            background-color: #fff;
            border: 0;
            padding: 0;
        }
        .react-datepicker__current-month {
            font-weight: 400;
        }
        .react-datepicker__navigation {
            top: 9px;
            .react-datepicker__navigation-icon {
                &:before {
                    top: 18px;
                }
            }
        }
        .react-datepicker__current-month {
            height: 46px;
            display: flex;
            align-items: center;
            justify-content: center;
        }
        .react-datepicker__day-names {
            display: flex;
            justify-content: space-between;
            text-transform: uppercase;
            padding: 7px 0;
            border-bottom: 1px solid #f3f3f3;
            border-top: 1px solid #f3f3f3;
            margin: 0.4rem;
            .react-datepicker__day-name {
                line-height: 1;
                color: #999999;
            }
        }
        .react-datepicker__week {
            display: flex;
            justify-content: space-between;
            text-transform: uppercase;
            .react-datepicker__day {
                padding: 2px 0;
                width: 30px;
                &.react-datepicker__day--selected {
                    background-color: ${DefaultPrimaryColor} !important;
                }
            }
        }
    }
`;

export const GroupInput = styled(Form.Group)`
    margin-bottom: 30px;
    position: relative;
    .bloc-password {
        position: relative;
        svg {
            position: absolute;
            top: 17px;
            right: 20px;
            font-size: 22px;
            transition: 0.3s all;
            &.fa-eye {
                color: #838383;
            }
        }
    }
    label {
        margin-bottom: 10px;
        font-size: 14px;
        color: #8181a5;
        line-height: 21px;
        display: flex;
        align-items: center;
        i {
            font-style: normal;
        }
        .info-input {
            svg {
                margin-left: 6px;
                cursor: pointer;
                font-size: 20px;
                color: ${DefaultPrimaryColor};
            }
            .MuiTooltip-arrow {
                color: ${DefaultPrimaryColor};
            }
            .MuiTooltip-tooltipArrow {
                background-color: ${DefaultPrimaryColor};
                font-family: "Open Sans";
                font-size: 11px;
                color: #FFF;
                line-height: 15px;
                max-width: 100%;
                margin-top: 10px;
                border-radius: 6px;
            }
        }
    }
    textarea {
        width: 100%;
        height: 137px;
    }
    .error-message {
        text-align: center;
        font-size: 13px;
        color: #de4646;
        display: block;
        margin-top: 6px;
    }

    .select-multi-values {
        .react-select__value-container {
            padding: 6px 20px 6px 5px;
        }
    }
    .react-select__group-heading {
        font-size: 14px;
        padding: 0 15px;
        & > div {
            & > span {
                &:first-child {
                    opacity: 0.5;
                }
            }
        }
    }
    .react-select__loading-indicator {
        align-items: center;
        position: absolute;
        right: 0px;
        margin: 0;
        &:before {
            order: 2;
            margin-left: 4px;
        }
    }
    .react-select__menu {
        background: #ffffff;
        border: 1px solid #ececf2;
        border-radius: 8px;
        padding: 0;
        box-shadow: none;
        .react-select__menu-list {
            padding: 0;
        }
        .react-select__option {
            background-color: transparent;
            color: #1c1d21;
            font-family: 'Open Sans';
            font-weight: 500;
            transition: 0.2s ease-in-out;
            padding: 5px 22px;
            &.react-select__option--is-disabled {
                opacity: 0.5;
            }
            &.react-select__option--is-focused {
                color: #fff;
                background-color: ${DefaultPrimaryColorLight};
            }
            &.react-select__option--is-selected {
                color: #fff !important;
                background-color: ${DefaultPrimaryColor};
            }
        }
    }
    .react-select__control {
        &.react-select__control--menu-is-open {
            border-bottom: 1px solid ${DefaultPrimaryColorLight};
            background-color: #f7f8ff;
        }
        .react-select__indicators {
            & > .react-select__indicator {
                padding: 8px 12px;
                svg {
                    display: none;
                }
                &:before {
                    content: "";
                    position: relative;
                    display: inline-block;
                    ${mixinIcon({ urlIcon: arrowDef, width: 18, height: 9 })};
                    transform: rotateX(180deg);
                    transition: 0.1s all;
                    transform-origin: center;
                }
            }
        }
        &.react-select__control--menu-is-open {
            .react-select__indicator {
                &:before {
                    transform: rotateX(0deg);
                }
            }
        }
    }
    .react-select__multi-value {
        background-color: ${DefaultPrimaryColor};
        color: #fff;
        font-size: 14px;
        border-radius: 6px;
        .react-select__multi-value__label {
            color: #fff;
        }
        .react-select__multi-value__remove {
            transition: 0.2s all;
        }
    }
    &.form-error {
        ${InputGroup}, .input-address, .quill, .react-select__control {
            border-bottom: 1px solid #de4646 !important;
            background-color: #ffe7e7 !important;
        }
        .datepicker-model3 {
            .form-control {
                border-bottom: 1px solid #de4646 !important;
                background-color: #ffe7e7 !important;
            }
        }
        .add-option-bloc {
            color: #de4646;
            svg {
                color: #de4646;
            }
        }
        .time_picker_container {
            .react_times_button {
                border-bottom: 1px solid #de4646;
                background-color: #ffe7e7;
            }
        }
        .react-date-picker .react-date-picker__wrapper {
            border-bottom: 1px solid #de4646;
            background: #ffe7e7;
        }
        .react-datetime-picker .react-datetime-picker__wrapper {
            border-bottom: 1px solid #de4646;
            background: #ffe7e7;
        }
    }
    &.select-uppercase {
        position: relative;
        input {
            text-transform: uppercase;
        }
        &:before {
            content: "";
            position: absolute;
            bottom: 15px;
            right: 20px;
        }
    }
    .notice-form-group {
        font-size: 13px;
        line-height: 22px;
        display: block;
        margin-top: 16px;
        margin-bottom: 0;
    }
    .select-icons {
        .react-select__single-value {
            & > div {
                span {
                    min-width: initial !important;
                }
            }
        }
        .react-select__option--is-focused,
        .react-select__option--is-selected {
            color: #fff !important;
            background-color: ${DefaultPrimaryColorLight} !important;
        }
    }
    &.disabled-wysiwyg {
        .quill {
            background-color: #e9ecef;
            pointer-events: none;
        }
    }
    .quill {
        background-color: #f9f9fc;
        font-size: 14px;
        font-weight: 400;
        border-bottom: 1px solid #d8d8d8;
        color: #000;
        min-height: 197px;
        border-radius: 8px 8px 0 0;
        .ql-editor {
            min-height: 150px;
        }
        .ql-toolbar {
            &.ql-snow {
                border: 0;
            }
        }
        .ql-container {
            &.ql-snow {
                border: 0;
                color: #000;
            }
        }
    }
    .c-select-option {
        position: relative;
        .input-select__icon {
            position: absolute;
            right: 0;
            top: 0;
        }
    }
    .item-option-liste {
        display: flex;
        align-items: center;
        margin-bottom: 14px;
        &.option-error {
            ${InputGroup} {
                border-bottom: 1px solid #f7f8ff;
            }
        }
        .input-option-liste {
            display: flex;
            align-items: center;
            margin-right: 30px;
            & > span {
                display: flex;
                align-items: center;
                i {
                    &.radio-option {
                        width: 20px;
                        height: 20px;
                        min-width: 20px;
                        background: #f0f0f3;
                        border-radius: 50%;
                        display: inline-block;
                    }
                    &.checkbox-option {
                        width: 20px;
                        height: 20px;
                        min-width: 20px;
                        border: 1px solid #e1dcdc;
                        border-radius: 4px;
                        display: inline-block;
                    }
                }
            }
            & > input {
                border: 0;
                height: 35px;
                padding: 0 16px;
                margin-left: 10px;
            }
        }
        .delete-option-liste {
            background: #ffffff;
            border: 1px solid #ececf2;
            border-radius: 8px;
            cursor: pointer;
            width: 35px;
            min-width: 35px;
            height: 35px;
            display: flex;
            align-items: center;
            justify-content: center;
            transition: 0.3s all;
            svg {
                font-size: 15px;
                color: #8181a5;
                transition: 0.3s all;
            }
            &:hover {
                border-color: #ff808b;
                svg {
                    color: #ff808b;
                }
            }
        }
    }
    .add-option-bloc {
        font-family: "Raleway";
        font-size: 14px;
        color: ${DefaultPrimaryColor};
        font-weight: 700;
        cursor: pointer;
        svg {
            margin-right: 6px;
            font-size: 18px;
            color: ${DefaultPrimaryColor};
        }
    }
    .input-address {
        font-family: "Open Sans";
        height: 46px;
        padding: 6px 20px;
        background-color: #f9f9fc;
        border-radius: 8px 8px 0 0;
        border: 0;
        font-size: 16px;
        font-weight: 400;
        border-bottom: 1px solid #d8d8d8;
        color: #000;
        &::-webkit-input-placeholder {
            font-size: 15px;
            color: #8181a5;
        }
        &::-moz-placeholder {
            font-size: 15px;
            color: #8181a5;
        }
        &:-ms-input-placeholder {
            font-size: 15px;
            color: #8181a5;
        }
        &:-moz-placeholder {
            font-size: 15px;
            color: #8181a5;
        }
        &:focus {
            background: #f7f8ff;
            border-bottom: 1px solid ${DefaultPrimaryColorLight};
            box-shadow: none;
            outline: none;
        }
    }
    &.search-input {
        .icon-search {
            position: absolute;
            top: 12px;
            left: 20px;
        }
        ${InputGroup} {
            padding-left: 18px;
        }
    }
    .input-icon {
        position: relative;
        ${InputGroup} {
            padding-left: 55px;
        }
        &.input-icon-password {
            ${InputGroup} {
                padding-right: 55px;
            }
        }
        .toggle-show-password {
            position: absolute;
            top: 50%;
            right: 19px;
            transform: translateY(-50%);
            cursor: pointer;
            svg {
                color: ${DefaultPrimaryColor};
                font-size: 21px;
            }
        }
        .icon-input {
            position: absolute;
            top: 50%;
            left: 19px;
            transform: translateY(-50%);
            svg {
                color: ${DefaultPrimaryColor};
                font-size: 21px;
            }
        }
    }
`;
export const BlocFilterInfo = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding-bottom: 22px;
    &.has-switch {
        max-width: calc(100% - 210px);
    }
    ${ButtenDef} {
        border: 0 !important;
        height: 36px;
        padding: 9px 16px;
        margin-right: 10px;
        margin-bottom: 10px;
        border-radius: 8px;
        font-size: 14px;
        font-family: "Lato";
        font-weight: 700;
        @media (max-width: 1380px) {
            padding: 9px 10px;
        }
        &.light-btn {
            color: ${DefaultPrimaryColor};
            background-color: transparent;
            svg {
                color: #960000;
            }
        }
        &.bleu-btn {
            background-color: ${DefaultPrimaryColor};
            color: #fff;
        }
        svg {
            margin: 0 7px;
            color: #fff;
        }
    }
    @media(max-width: 993px){
        padding-bottom: 5px;
    }
`;