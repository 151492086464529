import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import logo from '../../assets/images/mesa-logo.svg'
import React, { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { SideNavMenu } from "../../assets/styles/components/sideNaveStyle";
import SidebarClient from "./sidebars/Client";
import SidebarAdmin from "./sidebars/Admin";
import { Link } from "react-router-dom";
import { PRIVATE_ROUTE } from '../../config/routes';
import { useSelector } from "react-redux";

export default function Sidebar({ activeNavLink = "" }) {
    const isMobile = useMediaQuery({
        query: "(max-width: 993px)",
    });

    const auth = useSelector((state) => state.auth);

    const [toggleMenu, setToggleMenu] = useState(false);
    const [toggleMenuM, setToggleMenuM] = useState(false);

    useEffect(() => {
        if (toggleMenu) {
            document.getElementById("main-content").classList.add("menu-is-show");
        } else {
            document.getElementById("main-content").classList.remove("menu-is-show");
        }
    }, [toggleMenu]);
    
    useEffect(() => {
        if (isMobile) {
            if (toggleMenuM) {
                document.querySelector("body").classList.remove("toggled-sideNav");
                setToggleMenuM(false);
            }
        }
    }, [toggleMenuM]);

    return (
        <>
            <SideNavMenu open={toggleMenu} className={`sideNave-menu ${toggleMenu ? "toggled-sideNav" : ""}`}>
                <div className="logo">
                    <Link to={PRIVATE_ROUTE.HOME}>
                        <img src={logo} alt="Mesa" />
                    </Link>
                </div>
                <div className="bloc-sideNaveMenu">
                    <div className="bloc-MenuSideNav">
                            
                            {auth?.roles.includes('ROLE_CLIENT') && (
                                <SidebarClient />
                            )}
                            {auth?.roles.includes('ROLE_ADMIN') && (
                                <SidebarAdmin />
                            )}
                    </div>
                    <span
                        className="toggle-menu"
                        onClick={() => {
                            isMobile ? setToggleMenuM(true) : setToggleMenu(!toggleMenu);
                        }}
                    >
                        {toggleMenu ? <KeyboardArrowRightIcon /> : <KeyboardArrowLeftIcon />}
                    </span>
                </div>
            </SideNavMenu>
        </>
    );
}
