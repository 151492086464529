import { parseToRgb, rgbToColorString } from "polished";

export function validateEmail(email) {
    const re =
        /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i;
    return re.test(email);
}
export function validatePassword(password) {
    const re = /^(?=\S*[a-z])(?=\S*[A-Z])(?=\S*\d)(?=\S*([^\w\s]|[_]))\S{8,}$/;
    return re.test(password);
}

export function checkSiret(value) {
    const intValue = parseInt(value, 10);
    if (isNaN(intValue)) {
      return false;
    }
    const stringValue = String(intValue);
    if (stringValue.length !== 14) {
      return false;
    }
    return true;
  }

  export function rgbaToRgb(rgbaColor, bgColor = "#fff") {
    const rgba = parseToRgb(rgbaColor);
    const bg = parseToRgb(bgColor);

    const flattenedColor = {
        red: Math.round(rgba.alpha * rgba.red + (1 - rgba.alpha) * bg.red),
        green: Math.round(rgba.alpha * rgba.green + (1 - rgba.alpha) * bg.green),
        blue: Math.round(rgba.alpha * rgba.blue + (1 - rgba.alpha) * bg.blue),
    };

    return rgbToColorString(flattenedColor);
}

function calculateSubtotal(quoteProducts){
  const subtotal = quoteProducts.reduce((accumulator, quoteProduct) => {
      const price = quoteProduct.product?.price || 0;
      const quantity = parseFloat(quoteProduct.quantity) || 0;
      return accumulator + price * quantity;
  }, 0);

  return subtotal.toFixed(2);
};

function calculerFraisPort(subtotal){
  if (subtotal <= 500) {
    return 30;
  } else if (subtotal <= 1000) {
    return 35;
  } else if (subtotal <= 1500) {
    return 40;
  } else if (subtotal <= 2000) {
    return 45;
  } else {
    const fraisPort = Math.max(subtotal * 0.025, 5 * Math.log(subtotal), 30);
    return fraisPort.toFixed(2);
  }
};

function calculateTVA(subtotal){
  const TVARate = 0.2;
  const TVA = Math.round(subtotal * TVARate * 100) / 100;
  return TVA.toFixed(2);
};

function calculateTotalTTC(subtotal, TVA){
  TVA = parseFloat(TVA);
  var totalTTC = parseFloat(subtotal) + parseFloat(TVA);
  totalTTC = (totalTTC).toFixed(2);
  return totalTTC;
};

export function calculeTotalQuote(datas, libre = false){
  let subtotal = parseFloat(calculateSubtotal(datas));
  let fraisPort = calculerFraisPort(subtotal);
  if(libre == 'FRANCO') fraisPort = 0;
  subtotal = parseFloat(subtotal) + parseFloat(fraisPort);
  let TVA = calculateTVA(subtotal);
  let totalTTC = calculateTotalTTC(subtotal, TVA);
  subtotal = parseFloat(subtotal).toFixed(2);
  fraisPort = parseFloat(fraisPort).toFixed(2);
  TVA = parseFloat(TVA).toFixed(2);
  totalTTC = parseFloat(totalTTC).toFixed(2);
  return {
    subtotal: subtotal,
    fraisPort: fraisPort,
    TVA: TVA,
    totalTTC: totalTTC,
    totalProduits: datas.length,
  };
};

export function calculePriceQuantity(price, quantity){
  const unitPriceHT = parseFloat(price) || 0;
  const quantityValue = parseFloat(quantity) || 0;
  const priceHT = unitPriceHT * quantityValue;
  return priceHT.toFixed(2);
};

export function getMaxDeliveryDelay(prds, jours = false){
  const maxDelay = prds.map(item => item?.product?.delivery_delay)
  .filter(delay => typeof delay === 'number' || !isNaN(parseFloat(delay)))
  .reduce((max, current) => Math.max(max, parseFloat(current)), 0);
  if(jours){
    if(maxDelay === 0){
      return '';
    }else if(maxDelay === 1){
      return '1 jour';
    }else{
      return (maxDelay > 40 ? '40 - Nous consulter' : maxDelay+' jours');
    }
  }
  return maxDelay;
}
