import styled from "styled-components";
import { Modal } from "react-bootstrap";
import motif1Login from '../../images/motif1-login.svg';
import motif2Login from '../../images/motif2-login.svg';
import iconLink from '../../images/icons/icon-link.svg';
import arrowDef from "../../images/icons/arrow-def.svg";
import arrowDefColor from "../../images/icons/arrow-def-color.svg";
import {
    DefaultPrimaryColor,
    DefaultPrimaryColorLight,
    mixinIcon,
} from "../variables";
import { RadioButtonForm, CheckBoxForm, GroupInput, InputGroup } from './elementStyles';

export const MainPage = styled.div`
    display: flex;
    height: 100vh;
    background-color: #f5f5fa;
    font-family: 'Open Sans';
`;
export const ContentCadreItem = styled.div`
    margin-bottom: 25px;
    &:last-of-type {
        margin-bottom: 0;
    }
    
    &.table-cadreItem{
        padding: 0;
        background-color: transparent;
        border: 0;
        white-space: normal;
        .title-column {
            font-weight: 600;
            font-size: 15px;
            margin-bottom: 10px;
        }
        .content-bloc-item{
            .bloc-description-single-product{
                width: 90%;
                max-width: 100%;
                @media(max-width: 993px){
                    width: 100%;
                    padding-bottom: 20px;
                    margin-bottom: 20px;
                    border-bottom: 1px solid rgb(236, 236, 242);
                }
            }
            @media(max-width: 993px){
                padding-top: 30px;
            }
        }
        .wysiwyg-default{
            color: #666666;
            h1, h2, h3, h4, h5, h6, ul, ol{
                color: #000;
            }
        }
    }
    &.detail-room-salle {
        .bloc-detail-info-room {
            padding-left: 0px;
        }
        .row {
            justify-content: center;
            & > div {
                width: 100%;
                &:first-child {
                    width: 85%;
                    max-width: 100%;
                    .bloc-detail-info-room {
                        padding-left: 0;
                    }
                }
            }
        }
    }
    .titre-bloc-item {
        font-weight: 700;
        font-size: 18px;
        line-height: 27px;
        margin-bottom: 13px;
    }
    background: #ffffff;
    border-radius: 12px;
    padding: 25px 30px;
    border: 1px solid #ececf2;
    border-radius: 12px;
    position: relative;
    &.mb-25 {
        margin-bottom: 25px;
    }
    &.mb-0 {
        margin-bottom: 0px;
    }
    &.pb-0 {
        padding-bottom: 0px;
    }
    .content-bloc-item {
        padding-top: 5px;
    }
    .group-items-info {
        font-weight: 600;
        font-size: 14px;
        color: #8181a5;
        line-height: 18px;
        border-bottom: 1px dashed #ececf2;
        padding-bottom: 20px;
        margin-bottom: 15px;
        &:last-of-type {
            border: 0;
            margin-bottom: 0px;
            padding-bottom: 0px;
        }
        .item-info {
            margin-bottom: 14px;
            display: flex;
            &:last-child {
                margin-bottom: 0;
            }
        }
        .label-item-info {
            min-width: 180px;
            display: flex;
            align-items: flex-start;
            span {
                display: inline-block;
                margin-right: 5px;
            }
        }
        .value-item-info {
            font-weight: 700;
            color: #1c1d21;
            width: calc(100% - 180px);
            & > span {
                margin-bottom: 4px;
                margin-right: 0;
            }
        }
        .show-more-dates {
            text-decoration: underline;
            cursor: pointer;
            font-size: 14px;
            color: #8181a5;
            &:hover {
                text-decoration: none;
            }
        }
    }
    @media (max-width: 993px) {
        padding: 19px 16px;
    }
`;

export const MainContent = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100vh;
    overflow: auto;
    padding: 0px 28px 10px;
    width: 100%;
    transition: 0.4s ease-in-out;
    position: relative;
    &::before {
        content: "";
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: #000;
        opacity: 0;
        pointer-events: none;
        z-index: 9998;
        transition: 0.4s ease-in-out;
    }
    .main-wrapper {
        margin-bottom: 30px;
        position: relative;
    }
    .item-operation {
        display: flex;
        align-items: center;
        font-family: "Open Sans";
        font-size: 13.5px;
        font-weight: 600;
        margin-bottom: 10px;
        &:last-child{
            margin-bottom: 0;
        }
        &.total-operation{
            font-size: 16px;
            font-weight: 700;
        }
        .label-operation{
            width: 104px;
            min-width: 104px;
            margin-right: 20px;
        }
        @media(max-width: 575px){
            justify-content: space-between;
        }
    }
    .link-custom{
        margin-top: 20px;
        a{
            color: ${DefaultPrimaryColor};
            display: inline-flex;
            text-decoration: underline;
            cursor: pointer;
            &:before{
                content: "";
                position: relative;
                ${mixinIcon({ urlIcon: iconLink, width: 18, height: 14 })};
                top: 8px;
                margin-right: 7px;
            }
            &:hover {
                color: ${DefaultPrimaryColor};
                text-decoration: none;
            }
        }
    }
    .wysiwyg-default,
    .quill {
        font-size: 14px;
        line-height: 23px;
        a {
            color: ${DefaultPrimaryColor};
            text-decoration: underline;
            cursor: pointer;
            &:hover {
                color: ${DefaultPrimaryColor};
                text-decoration: none;
            }
        }
        ul {
            list-style: none;
            padding-left: 20px;
            li {
                display: flex;
                align-items: flex-start;
                margin-bottom: 8px;
                &:before {
                    content: "";
                    position: relative;
                    top: 9px;
                    width: 9px;
                    height: 9px;
                    border-radius: 50%;
                    background-color: ${DefaultPrimaryColor};
                    margin-right: 10px;
                }
            }
        }
        ol {
            list-style: none;
            counter-reset: li;
            padding-left: 36px;
            li {
                counter-increment: li;
                margin-bottom: 8px;
                &::before {
                    content: counter(li) ".";
                    color: ${DefaultPrimaryColor};
                    display: inline-block;
                    margin-left: -18px;
                    margin-right: 6px;
                    font-family: "Open Sans";
                }
            }
        }
    }

    .page-single-product{
        position: relative;
        padding-top: 5px;
        min-height: 100%;
    }
    .bloc-title-page{
        display: flex;
        justify-content: space-between;
        position: relative;
        .alert{
            margin-left: auto;
        }
    }
    @media(max-width: 993px){
        padding: 0 15px 10px;
    }
`;
export const BackButton = styled.div`
    padding-top: 20px;
    margin-bottom: 20px;
    position: absolute;
    top: 0;
    left: 0;
    .btn-default{
        background: #FFFFFF;
        border: 1px solid #E7E2E1;
        border-radius: 8px;
        color: #928886;
        &:hover{
            color: ${DefaultPrimaryColor};
        }
    }
    @media (max-width: 993px) {
        text-align: left;
        position: static;
        padding-top: 0;
    }
`;
export const HeadOptions= styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 20px 0;
    .user-bloc-info{
        padding-right: 19px;
        position: relative;
        margin-right: 11px;
        display: flex;
        align-items: center;
        &:before{
            content: "";
            position: absolute;
            top: 3px;
            bottom: 3px;
            right: 0;
            width: 1px;
            background: #E3E3E3;
        }
        .icon-user-profil{
            display: inline-flex;
            width: 30px;
            min-width: 30px;
            height: 30px;
            align-items: center;
            justify-content: center;
            border: 1px solid ${DefaultPrimaryColor};
            border-radius: 50%;
            margin-right: 10px;
        }
        .dropdown-toggle{
            background-color: transparent;
            border: 0;
            color: #000;
            font-size: 14px;
            line-height: 21px;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0;
            &:after{
                content: none;
            }
        }
    }
    .logout-menu {
        font-size: 14px;
        line-height: 21px;
        display: flex;
        align-items: center;
        cursor: pointer;
        color: #A9A9A9;
        transition: .3s ease-in-out;
        svg{
            path{
                transition: .3s ease-in-out;
            }
        }
        & > span{
            margin-left: 10px;
            display: inline-block;
        }
        &:hover{
            color: ${DefaultPrimaryColor} ;
            svg{
                path{
                    fill: ${DefaultPrimaryColor};
                }
            }
        }
        @media(max-width: 575px){
            & > span{
                display: none;
            }
        }
    }
`;

export const HeaderPage = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 20px;
    ${HeadOptions}{
        order: 2;
        margin-left: auto;
    }
    .bloc-toggle-mobile{
        width: 50px;
        height: 50px;
        min-width: 50px;
        margin-right: 24px;
    }
    .toggle-menu-mobile {
        position: relative;
        transition: .4s ease-in-out;
        width: 50px;
        height: 50px;
        min-width: 50px;
        background-color: #EAEAF1;
        border-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    @media(max-width: 993px){
        position: sticky;
        top: 0;
        z-index: 99;
        margin: 0 -15px;
        ${HeadOptions}{
            margin-left: 0;
            width: 100%;
            order: 1;
            background-color: rgb(245 245 250);
            padding: 20px 15px;
        }
        ${BackButton}{
            margin: 0;
            padding: 0;
        }
        .content-header{
            order: 2;
        }
        .user-bloc-info{
            margin-left: auto;
        }
    }
`;
export const DefaultModal = styled.div``;

export const TitlePage= styled.div`
    padding-bottom: 20px;
    color: ${DefaultPrimaryColor};
    font-weight: 600;
    font-size: 26px;
    letter-spacing: 0.43px;
    line-height: 27px;
    display: flex;
    align-items: center;
`;
export const LoaderBloc = styled.div`
    &.full-spinner {
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        z-index: 9999;
        top: 0;
        left: 0;
    }

    &.full-spinner.background-load {
        background: #fff;
    }

    &.full-spinner.md .loadingio-spinner-ripple {
        width: 104px;
        height: 104px;
    }

    &.full-spinner.md .ldio {
        transform: translateZ(0) scale(1);
    }

    &.full-spinner.md .ldio div {
        border-width: 2.08px;
        animation: ldio-md 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
    }

    &.full-spinner.xs .loadingio-spinner-ripple {
        width: 38px;
        height: 38px;
    }

    &.full-spinner.xs .ldio {
        transform: translateZ(0) scale(0.38);
    }

    &.full-spinner.xs .ldio div {
        border-width: 2px;
        animation: ldio-xs 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
    }

    .ldio div {
        position: absolute;
        border-width: 4px;
        border-style: solid;
        opacity: 1;
        border-radius: 50%;
        animation: ldio 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
    }

    .ldio div:nth-child(1) {
        border-color: ${DefaultPrimaryColor};
        animation-delay: 0s !important;
    }

    .ldio div:nth-child(2) {
        border-color: ${DefaultPrimaryColor};
        animation-delay: -0.5s !important;
    }

    .loadingio-spinner-ripple {
        width: 200px;
        height: 200px;
        display: inline-block;
        overflow: hidden;
        background: transparent;
    }

    .background-load .loadingio-spinner-ripple {
        background: #ffffffed;
    }

    .ldio {
        width: 100%;
        height: 100%;
        position: relative;
        transform: translateZ(0) scale(1);
        backface-visibility: hidden;
        transform-origin: 0 0;
    }

    .ldio div {
        box-sizing: content-box;
    }
`;
export const FooterBloc = styled.footer`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    font-size: 13px;
    line-height: 22px;
    color: #858588;
    font-weight: 600;
    .footer-navs {
        display: flex;
        flex-wrap: wrap;
        list-style: none;
        justify-content: flex-end;
        padding-left: 0;
        margin: 0;
        a {
            color: #858588;
            text-decoration: none;
            transition: 0.3s ease-in-out;
            &:hover {
                color: ${DefaultPrimaryColor};
            }
        }
        li {
            padding: 0 16px;
            position: relative;
            &:before {
                content: "";
                position: absolute;
                top: 50%;
                right: 0;
                transform: translateY(-50%);
                height: 10px;
                width: 1px;
                background-color: #8181a5;
            }
            &:first-child {
                padding-left: 0;
            }
            &:last-child {
                padding-right: 0;
                &:before {
                    content: none;
                }
            }
        }
    }
    @media (max-width: 993px) {
        .footer-navs {
            font-size: 12px;
            line-height: 18px;
            margin: 0;
            padding-left: 0;
            justify-content: flex-start;
            li {
                padding-left: 0;
                padding-right: 20px;
                &:before {
                    right: 10px;
                }
            }
        }
    }
    @media (max-width: 575px) {
        .footer-navs {
            order: 1;
            justify-content: center;
        }
        .copyright-footer{
            order: 2;
            width: 100%;
            margin: 10px 0 20px;
            text-align: center;
        }
    }
`;
export const ImageSvg = styled.div`
    g {
        &[fill="#4469E0"] {
            fill: ${DefaultPrimaryColor};
        }
        &[fill="#AFBCE6"] {
            fill: ${DefaultPrimaryColor};
            opacity: 0.6;
        }
    }
    polygon,
    rect {
        &[fill="#4469E0"] {
            fill: ${DefaultPrimaryColor};
        }
    }
    path {
        &[fill="#4469E0"] {
            fill: ${DefaultPrimaryColor};
        }
        &[stroke="#4469E0"] {
            stroke: ${DefaultPrimaryColor};
        }
    }
    polyline {
        &[stroke="#4469E0"] {
            stroke: ${DefaultPrimaryColor};
        }
    }
    svg {
        max-width: 100%;
        height: auto;
    }
`;

export const MessageInfoImage = styled.div`
    padding: 10vmin 30px;
    text-align: center;
    .btn-default {
        justify-content: center !important;
    }
    .content-messageInfo {
        margin-top: 18px;
        text-align: center;
        & > span {
            display: block;
            margin-bottom: 26px;
            font-family: "Open Sans";
            font-size: 18px;
            text-shadow: 0 0.2px;
            line-height: 32px;
        }
    }
    &.horizontal-info {
        display: flex;
        align-items: center;
        ${ImageSvg} {
            min-width: 255px;
            width: 255px;
        }
        .content-messageInfo {
            width: 100%;
            margin-top: 0;
            text-align: left;
            padding-left: 50px;
        }
    }
`;
export const BlocCader = styled.div`
    background: #ffffff;
    border-radius: 12px;
    padding: 25px;
    position: relative;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,0.04);
    &.mb-25 {
        margin-bottom: 25px;
    }
    &.mb-0 {
        margin-bottom: 0px;
    }
    &.our-devis{
        padding-top: 0;
        overflow: hidden;
    }
    .bloc-result-single-devis {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        .bloc-btns-default{
            padding-top: 20px;
            .btn-default{
                min-width: 214px;
                margin-right: 20px;
                &:last-child{
                    margin-right: 0;
                }
            }
        }
        .bloc-operations{
            background: #FCF5F3;
            border-radius: 15px;
            padding: 25px;
            margin-top: 20px;
        }
        @media(max-width: 767px){
            .bloc-operations{
                width: 100%;
                margin-top: 10px;
            }
            .bloc-btns-default{
                width: 100%;
            }
            
        }
        @media(max-width: 767px){
            .bloc-btns-default{
                .btn-default{
                    min-width: auto;
                    width: 100%;
                    margin-right: 0;
                    margin-bottom: 15px;
                }
            }
        }
    }
    &.detail-single-devis{
        padding-bottom: 0;
        & > .row{
            & > div{
                margin-bottom: 25px;
            }
        }
        .group-filter-single-product{
            & > div{
                &:last-child{
                    margin-bottom: 0;
                }
                label{
                    color: #928886;
                }
                .indicatorContainer{

                }
            }
            .productContener {
                position: relative;
                .clearSearch {
                    position: absolute;
                    top: 41px;
                    right: 11px;
                    cursor: pointer;
                }
                #search-input{
                    padding-right: 37px;
                }
            }
        }
        .bloc-operations{
            .item-operation{
                justify-content: space-between;
                &:first-child{
                    margin-top: 30px;
                }
                .label-operation{
                    width: auto;
                    margin-right: 20px;
                    color: rgb(146, 136, 134);
                }
                .value-operation{
                    font-size: 15px;
                }
                &.total-operation {
                    padding-top: 10px;
                    .label-operation{
                        font-size: 16px;
                        font-weight: 600;
                        color: #000;
                    }
                    .value-operation{
                        font-size: 17px;
                    }
                }
            }
        }
        .bloc-btn-add-devis {
            padding-top: 30px;
            .btn-default{
                width: 100%;
            }
        }
        ${ContentCadreItem}{
            height: 100%;
            .title-column{
                font-weight: 600;
                font-size: 15px;
                margin-bottom: 10px;
            }
            ul{
                padding-left: 0
            }
            .bloc-description-single-product{
                .wysiwyg-default{
                    color: #666666;
                    h1, h2, h3, h4, h5, h6, ul, ol{
                        color: #000;
                    }
                }
            }
        }
    }
    .bloc-results-autocomplate{
        width:200%;
        position: relative;
        overflow: hidden;
        .liste-search-autocomplate{
            overflow-y: auto;
            border: 1px solid #EEEEEE;
            border-radius: 0 0 6px 6px;
            max-height: 160px;
            & > div {
                cursor: pointer;
                padding: 8px 20px;
                background-color: #FFF;
                transition: .2s all;
                &:hover {
                    background-color: #DEEBFF !important;
                }
            }
        }
    }
    @media(max-width: 767px){
        padding: 25px 15px;
    }
`;
export const ServicesTableStyle = styled.div`
    .demandServicesName,
    .demandAgentsName {
        white-space: nowrap;
        text-overflow: ellipsis;
        max-width: 300px;
        display: block !important;
        overflow: hidden;
    }
    .btn-add {
        position: absolute;
        top: 25px;
        right: 22px;
        z-index: 1;
    }
    .table-responsive {
        position: relative;
        padding: 0 10px;
        margin: 0 -25px;
    }
    .table {
        font-size: 12px;
        font-weight: 200;
        margin-bottom: 0;
        th {
            font-weight: 600;
            padding: 20px 10px;
            &:first-child {
                padding-left: 24px;
            }
            .head-table-sort {
                display: block;
                span {
                    cursor: pointer;
                    margin-left: 5px;
                }
            }
        }
        thead {
            tr {
                background: rgb(247, 246, 254);
                background: linear-gradient(0deg, rgba(247, 246, 254, 1) 0%, rgba(255, 255, 255, 1) 100%);
            }
        }
        tbody {
            tr {
                cursor: pointer;
                transition: 0.5s all;
                border-bottom: 10px solid #f7f6fe;
                &.active {
                    overflow: hidden;
                    transform: scale(1.04);
                    color: #fff;
                    background-color: ${DefaultPrimaryColor};
                    position: sticky;
                    td {
                        background-color: ${DefaultPrimaryColor};
                        color: #fff;
                    }
                }
            }
            td {
                padding: 15px 12px;
                &:first-child {
                    padding-left: 24px;
                }
            }
        }
    }
    .divTable {
        display: table;
        width: 100%;
        font-size: 14px;
        line-height: 21px;
        margin: 0;
        padding: 0 16px;
        color: #5d5d5d;
        .no-content-table {
            padding: 20px 0;
            font-size: 15px;
            font-family: "Open Sans";
            font-weight: 600;
            width: 100%;
            text-align: center;
        }
        .divTableRow {
            display: table-row;
        }
        .divTableHeading {
            display: table-header-group;
            .divTableHead {
                display: table-cell;
                vertical-align: middle;
                padding: 15px 12px;
                font-family: "Open Sans";
                font-weight: 700;
                font-size: 14px;
                color: #928886;
                line-height: 18px;
                background: #FBFBFB;
                position: relative;
                white-space: nowrap;
                &.wrap{
                    white-space: normal;
                }
                &.center{
                    text-align: center;
                }
                & > div{
                    position: relative;
                    z-index: 1;
                }
                &:first-child {
                    &:before {
                        content: "";
                        position: absolute;
                        top: 0;
                        left: -10px;
                        bottom: 0;
                        width: 20px;
                        background: #FBFBFB;
                        z-index: 1;
                        border-radius: 15px 0 0 0px;
                    }
                }
                &:last-child {
                    &:before {
                        content: "";
                        position: absolute;
                        top: 0;
                        right: -10px;
                        bottom: 0;
                        width: 20px;
                        background: #FBFBFB;
                        border-radius: 0 15px 0 0px;
                    }
                }
                .head-table-sort {
                    div {
                        margin: 0;
                        cursor: pointer;
                    }
                    span {
                        cursor: pointer;
                        margin-left: 5px;
                    }
                }
                &.headTableCellCheckbox {
                    max-width: 24px;
                    .head-table-sort {
                        & > div {
                            & > div {
                                margin-bottom: 0;
                                .check-box-form {
                                    margin-bottom: 0;
                                    margin-right: 0;
                                }
                            }
                        }
                    }
                }
            }
        }
        .divTableCell {
            display: table-cell;
            vertical-align: middle;
            position: relative;
            border-bottom: 1px solid #eeeeee;
            border-top: 1px solid #FFF;
            &.nowrap {
                white-space: nowrap;
            }
            &.center{
                text-align: center;
                & > div{
                    text-align: center;
                    justify-content: center;
                }
                @media(max-width: 993px){
                    text-align: left;
                    & > div{
                        text-align: left;
                        justify-content: flex-start;
                        &:last-child{
                            text-align: right;
                        }
                    }
                }
            }
            & > div{
                &:nth-child(2){
                    @media(max-width: 993px){
                        text-align: right !important;
                    }
                }
            }
            .toggled-row-table{
                svg{
                    transform: rotate(90deg);
                    font-size: 22px;
                }
            }
            &.m-action {
                & > div{
                    display: inline-flex;
                    align-items: center;
                    & > button{
                        margin-right:24px;
                        border-radius: 18px;
                        height: 36px;
                        color: #000;
                        background-color: transparent;
                        border-color: #000;
                        &:hover{
                            color: ${DefaultPrimaryColor};
                            border-color: ${DefaultPrimaryColor};
                        }
                    }
                    & > span{
                        margin-right: 15px;
                        &.noClick{
                            cursor: pointer;
                        }
                        &.no-event-svg{
                            svg{
                                pointer-events: none;
                            }
                        }
                        &:last-of-type{
                            margin-right: 0;
                        }
                    }
                }
            }
            & > .divTableCellCheckbox {
                & > div {
                    margin-bottom: 0;
                    .check-box-form {
                        margin-bottom: 0;
                        margin-right: 0;
                    }
                }
            }
            &:before {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                right: -1px;
                bottom: 0px;
                background: transparent;
                z-index: 1;
            }
            .statut-table {
                background: #fff;
                border-radius: 5px;
                padding: 10px 5px;
                font-weight: 600;
                display: inline-flex;
                align-items: center;
                justify-content: center !important;
                margin: auto;
                min-width: 118px;
                font-size: 14px;
                line-height: 19px;
                text-align: center;
                &.pending {
                    background: #F28128;
                    color: #ffffff;
                }
                &.validated {
                    background: #1BAB2A;
                    color: #ffffff;
                }
                &.rejected{
                    background: #DE4646;
                    color: #ffffff;
                }
            }
            & > div {
                padding: 15px 12px;
                position: relative;
                z-index: 1;
                display: flex;
                align-items: center;
                .edit-row-table {
                    border-radius: 8px;
                    display: inline-flex;
                    align-items: center;
                    justify-content: center;
                    cursor: pointer;
                }
                .urgent-table {
                    background: #ffffff;
                    border-radius: 8px;
                    display: inline-flex;
                    align-items: center;
                    justify-content: center;
                    width: 36px;
                    min-width: 36px;
                    height: 36px;
                    svg {
                        color: #960000;
                        font-size: 18px;
                    }
                }
                & > span {
                    display: block;
                }
                &.name-commande {
                    min-width: 220px;
                }
                &.no-wrap {
                    white-space: nowrap;
                }
            }
            &.tableCellDate {
                & > div {
                    & > span {
                        margin-right: 5px;
                        &:last-child {
                            font-size: 13px;
                            color: #8181a5;
                            margin-right: 0px;
                        }
                    }
                }
            }
            &:first-child {
                & > div {
                    flex-wrap: nowrap;
                    white-space: nowrap;
                }
            }
            &:last-child {
                & > div {
                    text-align: right;
                    display: flex;
                    justify-content: flex-end;
                    &.no-wrap {
                        flex-wrap: nowrap;
                        .edit-row-table {
                            margin-left: 6px;
                            color: #8989ab;
                            &.icon-trash {
                                &:hover {  
                                    color: #ff808b;
                                }
                            }
                        }
                    }
                }
            }
        }
        .divTableHeading {
            background-color: #FBFBFB;
            display: table-header-group;
            font-weight: bold;
        }
        .divTableFoot {
            background-color: #eee;
            display: table-footer-group;
            font-weight: bold;
        }
        .divTableBody {
            display: table-row-group;
            font-family: "Open Sans";
            font-weight: 600;
            font-size: 15px;
            color: #1C1D21;
            line-height: 21px;
            .divTableRow {
                transition: 0.2s all;
                .divTableCell{
                    cursor: pointer;
                    &.m-action{
                        cursor: default;
                    }
                }
                .w-200 {
                    width: 200px;
                }
                .bloc-actions-item{
                    padding: 18px 12px;

                    &.isOpen{
                        position: relative;
                        z-index: 2;
                    }
                    .dropdown{
                        .dropdown-toggle{
                            width: 90px;
                            background-color: transparent;
                            border: 1px solid #000;
                            color: #000;
                            font-size: 14px;
                            font-family: "Open Sans";
                            font-weight: 600;
                            border-radius: 18px;
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            padding: 6px 14px;
                            margin-bottom: 0;
                            &:after{
                                border: 0;
                                ${mixinIcon({ urlIcon: arrowDef, width: 16, height: 7 })};
                                transform: rotateX(180deg);
                                top: 2px;
                                right: -4px;
                                position: relative;
                            }
                            &.show{
                                &:after{
                                    transform: rotateX(0deg);
                                    top: 0;
                                }
                            }
                        }
                        .dropdown-menu{
                            width: 100%;
                            min-width: auto;
                            padding: 0;
                            .dropdown-item{
                                font-size: 13px;
                                color: #928886;
                                font-family: 'Open Sans';
                                font-weight: 500;
                                background-color: transparent;
                                transition: .3s all;
                                &:hover{
                                    color: ${DefaultPrimaryColor};
                                }
                            }
                        }
                    }
                }
                &.collapseRow{
                    .divTable{
                        .divTableCell{
                            cursor: default;
                        }
                    }
                }
                &:first-child{
                    .divTableCell {
                        &:before{
                            top: 0 !important;
                        }
                    }
                }
                &.cursorAuto {
                    cursor: auto;
                }
                &:first-child {
                    .divTableCell {
                        border-top: 0;
                    }
                }
                &.active,
                &:hover, &.openRow {
                    .divTableCell {
                        color: #1C1D21;
                        .toggled-row-table{
                            svg{
                                color: ${DefaultPrimaryColor};
                            }
                        }
                        &.m-action{
                            & > div{
                                & > button{
                                    color: ${DefaultPrimaryColor};
                                    border-color: ${DefaultPrimaryColor};
                                    &:hover{
                                        color: #FFF;
                                        background-color: ${DefaultPrimaryColor};
                                    }
                                }
                            }
                        }
                        &:before {
                            background-color: #F6ECEA;
                            top: 5px;
                            bottom: 5px;
                        }
                        &:first-child {
                            &::before {
                                border-radius: 15px 0 0 15px;
                                left: 0px;
                            }
                        }
                        &:last-child {
                            &::before {
                                border-radius: 0 15px 15px 0;
                                right: 0px;
                            }
                        }
                        &.tableCellDate {
                            & > div {
                                & > span {
                                    &:last-child {
                                        color: #858588;
                                    }
                                }
                            }
                        }
                        & > div {
                            #icon-edit, #icon-trash, #icon-see {
                                path{
                                    fill: ${DefaultPrimaryColor};
                                }
                            }
                            #icon-copie {
                                rect{
                                    fill: #F6ECEA;
                                    stroke: ${DefaultPrimaryColor};
                                }
                            }
                        }
                        
                        @media(max-width: 993px){
                            &:before{
                                bottom: 0;
                                top: 0;
                            }
                            &:first-child {
                                &::before {
                                    border-radius: 15px 15px 0 0;
                                }
                            }
                            &:last-child {
                                &::before {
                                    border-radius: 0 0 15px 15px;
                                }
                            }
                        }
                    }
                }
                &.openRow{
                    .divTableCell {
                        border-color: #F6ECEA;
                        .toggled-row-table{
                            svg{
                                transform: rotate(-90deg);
                            }
                        }
                        &:before{
                            bottom: 0;
                        }
                        &:first-child {
                            &::before {
                                border-radius: 15px 0 0 0px;
                            }
                        }
                        &:last-child {
                            &::before {
                                border-radius: 0 15px 0px 0;
                            }
                        }
                        @media(max-width: 993px){
                            &:first-child {
                                &::before {
                                    border-radius: 15px 15px 0 0;
                                }
                            }
                            &:last-child {
                                &::before {
                                    border-radius: 0;
                                }
                            }
                        }
                    }
                }
                &.active-day {
                    transform: scale(1.006);
                    .divTableCell {
                        border-bottom: 8px solid #ffff;
                        &:before {
                            border-top: 1px solid ${DefaultPrimaryColor};
                            border-bottom: 1px solid ${DefaultPrimaryColor};
                        }
                        &:first-child {
                            &::before {
                                border-radius: 15px 0 0 15px;
                                left: -11px;
                                border-left: 1px solid ${DefaultPrimaryColor};
                            }
                        }
                        &:last-child {
                            &::before {
                                border-radius: 0 15px 15px 0;
                                right: -11px;
                                border-right: 1px solid ${DefaultPrimaryColor};
                            }
                        }
                    }
                }
                &.collapseRow{
                    &:hover{
                        .divTableCell{
                            border-bottom: 1px solid rgb(238, 238, 238);    
                        }
                    }
                    .detail-collapseRow{
                        background: #FCF5F3;
                        margin: 0 0 10px;
                        display: block;
                        border-radius: 0 0 15px 15px;
                        &.commande-detail-collapseRow{
                            .table-responsive{
                                @media(min-width: 994px){
                                    width: calc(100% - 90px);
                                }
                            }
                        }
                    }
                    .table-responsive{
                        padding: 0;
                        margin: 0;
                    } 
                    .detail-collapseRow{
                        padding: 26px 36px 20px;
                        
                    }
                    .bloc-operations {
                        padding-top: 20px;
                        margin-top: 20px;
                        border-top: 1px solid #E6DBD9;
                    }
                    .action-bloc-operation{
                        padding-top: 20px;
                        margin-top: 20px;
                        border-top: 1px solid #E6DBD9;
                        display: flex;
                        justify-content: space-between;
                        align-items: flex-end;
                        flex-wrap: wrap;
                        .bloc-operations {
                            padding-top: 0px;
                            margin-top: 0px;
                            border-top: 0;
                            margin-bottom: 10px;
                            margin-right: 20px;
                        }
                        .action-operation-button{
                            .blockBtnModalConfirm {
                                text-align: center;
                                display: flex;
                                flex-wrap: wrap;
                                & > button {
                                    margin: 0 10px 10px;
                                    min-width: 160px;
                                }
                            }
                            @media(max-width: 575px){
                                width: 100%;
                                margin-top: 10px;
                                .blockBtnModalConfirm {
                                    & > button {
                                        margin: 0 0px 10px;
                                        width: 100%;
                                    }
                                }
                            }
                        }
                    }
                    .divTable {
                        .divTableHeading {
                                background: transparent;
                            .divTableHead{
                                background: transparent;
                                padding: 10px 12px;
                                &:first-child {
                                    padding-left: 0;
                                }
                                &:last-child {
                                    padding-right: 0;
                                }
                                &:before, &:after{
                                    content: none !important;
                                }
                                &.center{
                                    text-align: center;
                                }

                            }
                        }
                        .divTableBody{
                            font-size: 14px;
                        }
                        .divTableCell {
                            border-bottom: 0;
                            & > div{
                                padding: 10px 12px;
                            }
                            &:first-child {
                                & > div{
                                    padding-left: 0;
                                }
                            }
                            &:last-child {
                                & > div{
                                    padding-right: 0;
                                }
                            }
                        }
                    }
                    .divTableCell{
                        padding: 0;
                        border-top: 0;
                        &:last-child {
                            & > div{
                                text-align: left;
                                justify-content: flex-start;
                            }
                        }
                        &:before{
                            content: none;
                        }
                    }
                }
            }
        }

        &.align-left {
            .divTableCell {
                text-align: left;
            }

            .divTableHeading .divTableHead .head-table-sort {
                justify-content: left;
            }

            .no-content-table {
                text-align: center;
            }
        }
    }

    @media (max-width: 993px) {
        .divTable {
            margin-bottom: 10px;
            display: block;
            .divTableHeading {
                display: none;
            }

            .divTableBody {
                display: block;
                .divTableRow {
                    display: block;
                    background: white;
                    border-radius: 15px;
                    padding: 0;
                    margin: 15px 0px;
                    border: 1px solid #e3e3e3;
                    overflow: hidden;
                    transition: none;
                    &.no-border{
                        border: 0 !important;
                    }
                    &:first-child{
                        margin-top: 0;
                    }
                    &:last-child{
                        margin-bottom: 0;
                    }
                    &.openRow, &:hover{
                        border-color: #FFF;
                        border-bottom: 0;
                        margin-bottom: 0;
                        border-radius: 15px 15px 0 0; 
                    }
                    &.collapseRow{
                        border-color: #FFF;
                        border-top: 0;
                        margin-top: 0;
                        border-radius: 0 0 15px 15px; 
                        .detail-collapseRow {
                            padding: 0px 20px 20px;
                            margin-bottom: 0;
                            border-radius: 0 !important;
                            &.commande-detail-collapseRow {
                                .table-responsive{
                                    width: 100%;
                                }
                            }
                        }
                        .divTableCell{
                            & > div{
                                width: 100%;
                            }
                        }
                        .divTable{
                            padding: 0;
                            margin-bottom: 0;
                            .divTableCell{
                                & > div{
                                    width: auto;
                                    &:first-child{
                                        padding-left: 0 !important;
                                    }
                                    &:last-child{
                                        padding-right: 0 !important;
                                    }
                                }
                            }
                            .divTableRow{
                                background-color: transparent;
                                border: 0;
                                border-bottom: 1px solid #e3e3e3; 
                                border-radius: 0;
                                &:last-child{
                                    margin-bottom: 0;
                                    border-bottom: 0;
                                }
                            }
                        }
                    }
                    .divTableCell {
                        display: flex;
                        justify-content: space-between;
                        align-items: flex-start;
                        border: 0;
                        padding: 6px 0;
                        width: 100% !important;
                        .head-table-mobile {
                            font-family: "Open Sans";
                            font-weight: 700;
                            font-size: 13px;
                            color: rgb(146, 136, 134);
                            line-height: 18px;
                            position: relative;
                        }
                        .statut-table{
                            margin: 0 10px !important;
                        }
                        & > div {
                            margin: 0;
                            min-height: auto;
                            padding: 7px 12px;
                        }
                        & > label {
                            position: relative;
                            z-index: 1;
                            margin: 0;
                            padding: 7px 8px;
                            font-weight: 600;
                        }

                        &:first-child > div {
                            border-radius: 15px 15px 0 15px;
                        }

                        &.m-action {
                            justify-content: center;
                            padding: 10px 0;
                            width: 100% !important;
                            & > div {
                                justify-content: center;
                                flex-wrap: wrap;
                                button {
                                    width: 100%;
                                    margin-right: 0;
                                    margin-bottom: 15px;
                                    &.btn-default{
                                        background-color: ${DefaultPrimaryColor};
                                        border-color: ${DefaultPrimaryColor};
                                        color: #FFF;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;
export const LoginPage = styled.div`
    min-height: 100vh;
    .wysiwyg-default,
    .quill {
        font-size: 13px;
        line-height: 18px;
        a {
            color: ${DefaultPrimaryColor};
            &:hover {
                text-decoration: none;
            }
        }
        ul {
            list-style: none;
            padding-left: 10px;
            li {
                display: flex;
                align-items: flex-start;
                margin-bottom: 16px;
                &:before {
                    content: "";
                    position: relative;
                    top: 4px;
                    width: 10px;
                    min-width: 10px;
                    height: 10px;
                    border-radius: 50%;
                    background-color: #E0BEB7;
                    margin-right: 10px;
                }
            }
        }
    }
    & > .row{
        margin: 0;
        min-height: 100vh;
        & > div{
            padding: 0;
            @media(min-width: 994px){
                &.bloc-form-login{
                    width: calc(100% - 400px);
                    order: 2
                }
                &.bloc-welcome-login{
                    width: 400px;
                }
            }
            @media(min-width: 1200px){
                &.bloc-form-login{
                    width: calc(100% - 557px);
                    order: 2
                }
                &.bloc-welcome-login{
                    width: 557px;
                }
            }
        }
    }
    .bloc-content-login{
        .center-link{
            margin: auto;
            display: table;
        }
        padding: 100px 30px;
        position: sticky;
        top: 0;
        @media(max-width: 993px){
            padding: 60px 20px 80px;
        }
        .content-login{
            width: 442px;
            max-width: 100%;
            margin: auto;
        }
        .content-register{
            width: 684px;
            max-width: 100%;
            margin: auto;
            ${RadioButtonForm}{
                padding-top: 26px;
            }
            ${CheckBoxForm}{ 
                padding-top: 46px;
                .check-box-form{
                    margin-right: 0;
                    label{
                        font-weight: 400;
                        font-size: 14px;
                        a{
                            color: #893E2F;
                            display: inline;
                            margin-left: 5px;
                            text-decoration: underline;
                            &:hover{
                                text-decoration: none;
                            }
                        }
                    }
                }
            }
        }
        form{
            ${GroupInput}{
                &.form-error{
                    ${InputGroup}, .input-address, .quill, .react-select__control {
                        border-color: #de4646 !important;
                    }
                }
            }
            & > div{
                label{
                    color: #893E2F;
                    font-size: 16px;
                    line-height: 22px;
                    font-weight: 600;
                }
                .form-control{
                    border: 1px solid #893E2F;
                    height: 52px;
                    border-radius: 26px;
                    background-color: #FFF;
                    &:focus{
                        background-color: #fdf2efb3;
                    }
                }
            }
            .btn-default{
                width: 100%;
                height: 100%;
                border-radius: 26px;
            }
            .bloc-btn-login{
                text-align: center;
                .btn-default{
                    width: 332px;
                    display: inline-flex;
                }
            }
        }
        .forget-password {
            font-size: 14px;
            line-height: 19px;
            margin-top: -15px;
            margin-bottom: 30px;
            font-weight: 700;
            a{
                color: #FF9680;
                text-decoration: underline;
                &:hover{
                    text-decoration: none
                }
            }
        }
    }
    .switch-login{
        color: #893E2F;
        font-size: 16px;
        margin-top: 22px;
        a{
            color: #FF9680;
            font-weight: 700;
            text-decoration: underline;
            display: inline-block;
            margin-left: 5px;
            &:hover{
                text-decoration: none
            }
        }
    }
    .head-login{
        text-align: center;
        color: ${DefaultPrimaryColorLight};
        font-size: 16px;
        line-height: 22px;
        font-weight: 700;
        margin-bottom: 40px;
        & > span{
            display: block;
        }
        .logo-login{
            margin-bottom: 20px;
            img{
                width: 182px;
            }
        }
        h1{
            font-size: 38px;
            font-weight: 700;
            color: #893E2F;
            line-height: 42px;
            margin-bottom: 11px;
        }
    }
    .content-info-login{
        height: 100%;
        background-color: #893E2F;
        position: relative;
        color: #FFF;
        padding: 104px 40px 104px 80px;
        @media(max-width: 1380px){
            padding-left: 40px;
        }
        @media(max-width: 993px){
            display: none;
        }
        @media (max-width: 1280px) {
            padding: 40px 20px 40px 20px;
        }
        @media (max-height: 800px) {
            padding: 40px 20px 40px 20px;
        }
        &:before{
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            ${mixinIcon({ urlIcon: motif1Login, width: 107, height: 147 })};
        }
        &:after{
            content: "";
            position: absolute;
            bottom: 0;
            right: 0;
            ${mixinIcon({ urlIcon: motif2Login, width: 132, height: 112 })};
        }
        .detail-content-login{
            h2{
                font-size: 28px;
                font-weight: 700;
                line-height: 38px;
                margin-bottom: 48px;
                @media (max-width: 1280px) {
                    font-size: 25px;
                    margin-bottom: 35px;
                }
                @media (max-height: 800px) {
                    font-size: 25px;
                    margin-bottom: 35px;
                }
            }
            .steps-login {
                padding-left: 50px;
                list-style: none;
                li {
                    position: relative;
                    padding-top: 8px;
                    padding-bottom: 30px;
                    &:before {
                        content: "";
                        position: absolute;
                        top: 6px;
                        bottom: 0;
                        left: -28px;
                        width: 2px;
                        background: #FFF;
                    }
                    &:last-child{
                        &:before{
                            content: none;
                        }
                    }
                }
                .number-step {
                    position: absolute;
                    top: 0;
                    left: -50px;
                    width: 44px;
                    height: 44px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border: 6px solid #893E2F;
                    border-radius: 50%;
                    background-color: #893E2F;
                    span {
                        border: 2px solid #FFF;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width: 100%;
                        height: 100%;
                        border-radius: 50%;
                        text-align: center;
                        font-size: 14px;
                        font-weight: 700;
                    }
                }
                .title-step {
                    font-size: 18px;
                    font-weight: 700;
                    margin-bottom: 6px;
                }
                .desc-step {
                    font-size: 15px;
                    line-height: 20px;
                    margin-bottom: 0;
                    font-weight: 600;
                }
            }
            .title-savoir {
                font-size: 14px;
                line-height: 19px;
                font-weight: 700;
                margin-bottom: 22px;
            }
        }
    }
`;
export const MessageSave = styled.div`
        position: absolute;
        bottom: 0;
        right: 0;
        min-height: 63px;
        display: flex;
        align-items: center;
        border-radius: 12px;
        padding: 8px 20px;
        font-size: 15px;
        z-index: 1;
        margin-bottom: 15px;
        .alert-detail {
            position: relative;
            padding-left: 32px;
        }
        .icon-type-alert {
            position: absolute;
            left: 0;
        }
        .text-alert{
            p{
                font-weight: 700;
                margin-bottom: 0;
            }
        }
        &.success{
            background: #E6F3E7;
            border: 1px solid #BDE8C1;
            color: #44B24F;
        }
        &.error{
            background: rgb(255 244 244);
            border: 1px solid rgb(253 214 214);
            color: rgb(222, 70, 70);
        }
`;



export const FilterSelect = styled.div`
    display: flex;
    align-items: center;
    .select-multi-values{
        margin-bottom: 10px;
    }
    & > span {
        font-size: 13px;
        font-family: "Raleway";
        font-weight: 700;
        color: #8181a5;
        display: inline-flex;
        align-items: center;
    }
    .react-select__control {
        border: 0 !important;
        box-shadow: none !important;
        min-height: 36px;
        &.react-select__control--menu-is-open {
            background-color: #fff;
        }
    }
    .react-select__indicator-separator {
        width: 0;
    }
    .react-select__indicator {
        padding: 8px 0 !important;
        &:before{
            ${mixinIcon({ urlIcon: arrowDefColor, width: 12, height: 7, important:true })};
        }import default from '../../../vars';

    }
    .react-select__value-container {
        padding: 2px 8px;
    }
    .react-select__control,
    .react-select__placeholder {
        color: ${DefaultPrimaryColor};
        font-family: "Lato";
        font-weight: 700;
        font-size: 14px;
        background-color: #fff;
    }
    .react-select__single-value {
        color: ${DefaultPrimaryColor};
        font-size: 14px;
        font-family: Lato;
        font-weight: 700;
    }
    .react-select__menu {
        width: max-content;
        right: 0;
        box-shadow: none;
        background: #ffffff;
        border: 1px solid #ececf2;
        border-radius: 8px;
        margin-top: 0;
    }
    
`;